import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import api from "../api";
import { useAlert } from "react-alert";
import { Loader } from "./global/loader";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import userAction from "../redux/users/action";
import { Modal } from "react-bootstrap";
import { Pagination, Stack } from "@mui/material";
import { ReactionsMain } from "./reactionsMain";
import { useLocation } from "react-router-dom";
import Intercom from "@intercom/messenger-js-sdk";

export const UserPost = ({ SubsData }) => {
  const alert = useAlert();
  const userStore = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const { setUser } = userAction;
  const location = useLocation();

  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [noOfPost, setnoOfPost] = useState(0);
  const [activeNav, setActiveNav] = useState("signals");
  const [isSubscription, setIsSubscription] = useState(false);

  const [showMore, setShowMore] = useState(false);
  const handleCloseMore = () => setShowMore(false);
  const handleShowMore = () => setShowMore(true);
  const [activeImg, setActiveImg] = useState("");

  const getPosts = async () => {
    try {
      let payload = {
        page: page,
        userId: userStore?.users?.user?._id,
      };
      setLoader(true);
      let token = localStorage.getItem("token");
      axios.defaults.headers.common["Authorization"] = token;
      const postData = await api.postGet(payload);
      // console.log(postData);
      // setData([...data, ...postData.post]);
      setData(postData.post);

      setnoOfPost(postData.count);
      setLimit(postData.limit);
      setLoader(false);
    } catch (error) {
      alert.show(error?.message || "Something went wrong");
      console.log(error?.message);
      setLoader(false);
    }
  };

  const handleChange = (event, value) => {
    setPage(value);
    var scrollDiv = document.getElementById("cWidget").offsetTop;
    window.scrollTo({ top: scrollDiv, behavior: "smooth" });
  };

  useEffect(() => {
    if (SubsData?.isSubscription) {
      getPosts();
    } else {
      setLoader(false);
    }
  }, [page, SubsData]);

  // const checkUserSession = async () => {
  //   let token = localStorage.getItem("token");
  //   // console.log(token);
  //   if (token) {
  //     let payload = { token: token };
  //     const registerationResponse = await api.verifySession(payload);
  //     // console.log(registerationResponse);
  //     if (registerationResponse?.token) {
  //       axios.defaults.headers.common["Authorization"] =
  //         registerationResponse?.token;
  //       return true;
  //     } else {
  //       dispatch(setUser({}));
  //       localStorage.removeItem("token");
  //       return false;
  //     }
  //   }
  // };

  useEffect(() => {
    if (SubsData?.isSubscription) {
      setIsSubscription(SubsData?.isSubscription);

      Intercom({
        app_id: "u3zetb7y",
        user_id: userStore?.users?.user?._id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
        name: userStore?.users?.user?.name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
        email: userStore?.users?.user?.email, // IMPORTANT: Replace "user.email" with the variable you use to capture  the user's email  created_at: user.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
      });
      let iframe = document.querySelector(".intercom-lightweight-app");

      if (iframe) {
        iframe.style.display = "block";
      }
    }
    return () => {
      // Find and remove the HubSpot iframe
      let iframe = document.querySelector(".intercom-lightweight-app");
      if (iframe) {
        iframe.style.display = "none";
      }
    };
  }, [userStore?.users, SubsData]);

  const handleActiveImg = (link) => {
    setActiveImg(link);
    handleShowMore();
  };

  // hubspot chat
  // useEffect(() => {
  //   // Check if the current route is '/dashboard'
  //   if (location.pathname === "/dashboard") {
  //     let iframe = document.querySelector('iframe[src*="hubspot.com"]');
  //     let chatDiv = document.getElementById(
  //       "hubspot-messages-iframe-container"
  //     );
  //     if (iframe) {
  //       iframe.style.display = "block";
  //       chatDiv.style.visibility = "visible";
  //     }
  //     // Dynamically inject the HubSpot chat script
  //     const script = document.createElement("script");
  //     script.src = "https://js-eu1.hs-scripts.com/145919709.js"; // Replace with your HubSpot portal ID
  //     script.async = true;
  //     document.body.appendChild(script);

  //     // Cleanup the script when leaving the /dashboard route
  //     return () => {
  //       // Find and remove the HubSpot iframe
  //       let iframe = document.querySelector('iframe[src*="hubspot.com"]');
  //       let chatDiv = document.getElementById(
  //         "hubspot-messages-iframe-container"
  //       );
  //       if (iframe) {
  //         iframe.style.display = "none";
  //         chatDiv.style.visibility = "hidden";
  //       }
  //       document.body.removeChild(script);
  //     };
  //   }
  // }, [location.pathname]);

  return (
    <>
      {loader ? (
        <div className="postBgLoad"></div>
      ) : (
        <div className="signalsPage">
          <div className="contact-widget-area pb-70" id="cWidget">
            <div className="contact-widget-area-inner">
              <div className="container">
                {isSubscription ? (
                  <>
                    {/* <div className="section-title text-center mb-45 mt-4">
                    <span />
                    <h2 className="text-uppercase">waqar zaka updates</h2>
                  </div> */}
                    <div className="subscriptionNav">
                      <div
                        className={
                          activeNav == "signals" ? "link active" : "link"
                        }
                        onClick={() => setActiveNav("signals")}
                      >
                        Crypto Trades
                      </div>
                      <div
                        className={
                          activeNav == "commodities" ? "link active" : "link"
                        }
                        onClick={() => setActiveNav("commodities")}
                      >
                        Stock & Commodities Trades
                      </div>
                      <div
                        className={
                          activeNav == "market" ? "link active" : "link"
                        }
                        onClick={() => setActiveNav("market")}
                      >
                        Market Updates
                      </div>
                      {/* <div
                        className={
                          activeNav == "broadcast" ? "link active" : "link"
                        }
                        onClick={() => setActiveNav("broadcast")}
                      >
                        Broadcast
                      </div> */}
                      <div
                        className={
                          activeNav == "education" ? "link active" : "link"
                        }
                        onClick={() => setActiveNav("education")}
                      >
                        Education
                      </div>
                      {/* <div
                        className={
                          activeNav == "story" ? "link active" : "link"
                        }
                        onClick={() => setActiveNav("story")}
                      >
                        Story
                      </div> */}
                    </div>
                    {activeNav == "signals" && (
                      <>
                        <div className="userPostSec">
                          {data.length == 0 && !loader && (
                            <h3 className="text-center mt-4">
                              No Data Available
                            </h3>
                          )}

                          <div className="row">
                            {data.map((item, key) => (
                              <>
                                <PostCardComp
                                  item={item}
                                  key={key}
                                  handleActiveImg={handleActiveImg}
                                />
                              </>
                            ))}
                          </div>
                        </div>
                        {/* {limit * page < noOfPost && (
                          <div className="showMoreBtn text-center mt-5">
                            <button
                              className="themeBtn mx-auto"
                              onClick={() => handleChange(page + 1)}
                            >
                              Show More
                            </button>
                          </div>
                        )} */}
                        <div className="d-flex justify-content-center">
                          <Stack spacing={2}>
                            <Pagination
                              count={Math.ceil(noOfPost / limit)}
                              page={page}
                              onChange={handleChange}
                            />
                          </Stack>
                        </div>
                      </>
                    )}
                    {activeNav == "commodities" && (
                      <StocksComp handleActiveImg={handleActiveImg} />
                    )}
                    {activeNav == "market" && (
                      <MarketComp handleActiveImg={handleActiveImg} />
                    )}
                    {/* {activeNav == "broadcast" && <BroadCastcomp />} */}
                    {activeNav == "education" && <Videocomp />}
                    {/* {activeNav == "story" && <Storycomp />} */}
                  </>
                ) : (
                  <h2 className="addWalletText">
                    This feature is only available for paid users.
                  </h2>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {loader && <Loader />}

      <Modal size="lg" show={showMore} onHide={handleCloseMore} centered>
        <Modal.Body>
          <div className="otpModalMain broadcastModal">
            <div className="closeIcon" onClick={handleCloseMore}>
              <i className="fa-solid fa-xmark" />
            </div>

            <div className="showMoreModalM">
              <div className="showMoreModal">
                <img className="w-100" src={activeImg} alt="" />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const PostCardComp = ({ item, handleActiveImg }) => {
  const userStore = useSelector((state) => state.userReducer);
  const baseUrl = `${window.location.protocol}//${window.location.host}`;

  const [userReacted, setUserReacted] = useState({});
  const [allReactions, setAllReactions] = useState([]);
  const [reactionCounts, setReactionCounts] = useState({});

  const addReaction = async (reactionType) => {
    try {
      let payload = {
        userId: userStore?.users?.user?._id,
        postId: item?._id,
        reactionType: reactionType,
      };
      if (userReacted?.reactionType == reactionType) {
        console.log("already reacted");
        return;
      }

      const resp = await api.cryptoReactionAdd(payload);
      console.log(resp);
      if (resp?.reaction?.userId) {
        // getReaction(item?._id);
        // Update the local state to reflect the new reaction count and the user's reaction
        setReactionCounts((prevCounts) => {
          const updatedCounts = { ...prevCounts };
          // If there's an existing reaction, decrement its count
          if (userReacted?.reactionType) {
            updatedCounts[userReacted?.reactionType] = Math.max(
              0,
              updatedCounts[userReacted?.reactionType] - 1
            );
          }
          // Increment the count for the new reaction type
          updatedCounts[reactionType] = (updatedCounts[reactionType] || 0) + 1;

          return updatedCounts;
        });
        setUserReacted(resp?.reaction);
      }
    } catch (error) {}
  };
  const deleteReaction = async (id, reactionType) => {
    try {
      let payload = {
        id: id,
        reactionType: reactionType,
        postId: item?._id,
      };

      const resp = await api.cryptoReactionDelete(payload);
      // console.log(resp);
      if (resp?.message == "Reaction deleted") {
        // Update the local state to reflect the removed reaction
        setReactionCounts((prevCounts) => {
          const updatedCounts = { ...prevCounts };
          if (updatedCounts[reactionType]) {
            updatedCounts[reactionType] -= 1;
          }
          return updatedCounts;
        });
        setUserReacted({});
        // getReaction(item?._id);
      }
    } catch (error) {
      console.log(error?.message);
    }
  };
  // const getReaction = async (id) => {
  //   try {
  //     let payload = {
  //       postId: id,
  //     };

  //     const resp = await api.cryptoReactionGet(payload);
  //     // console.log(resp);
  //     if (resp?.success) {
  //       setAllReactions(resp?.reactions);
  //     }
  //   } catch (error) {
  //     console.log(error?.message);
  //   }
  // };

  useEffect(() => {
    if (item?.reactionCount) {
      setReactionCounts(item?.reactionCount || {});
    }
  }, []);

  // useEffect(() => {
  //   if (item?._id) {
  //     getReaction(item?._id);
  //   }
  // }, []);

  return (
    <div className="col-md-6 mb-3">
      <div className="postCard">
        {item?.imageTitle && (
          <div
            className="imgDiv"
            onClick={() =>
              handleActiveImg(
                `${process.env.REACT_APP_S3URL}/img_${item.imageTitle}.${item.imageFormat}`
              )
            }
          >
            <img
              src={`${process.env.REACT_APP_S3URL}/img_${item.imageTitle}.${item.imageFormat}`}
              alt=""
            />
          </div>
        )}
        <div className="desc">
          <div
            dangerouslySetInnerHTML={{
              __html: item?.description,
            }}
          />
          <div className="date"> {moment(item?.createdAt).fromNow()}</div>
        </div>
        {/* <ReactionsMain
          post={item}
          addReaction={addReaction}
          deleteReaction={deleteReaction}
          setUserReacted={setUserReacted}
          userReacted={userReacted}
        />
        <div className="statesRow">
          <div className="statsR">
            {Object.keys(reactionCounts).map(
              (reactionType) =>
                reactionCounts[reactionType] > 0 && (
                  <div className="stats">
                    {reactionType == "like" ||
                    reactionType == "love" ||
                    reactionType == "sad" ? (
                      <img
                        src={`${baseUrl}/emogis/${reactionType}.png`}
                        alt="icon"
                      />
                    ) : (
                      <img
                        src={`https://flagcdn.com/w20/${reactionType.toLowerCase()}.png`}
                        alt="icon"
                      />
                    )}
                    {reactionCounts[reactionType]}
                  </div>
                )
            )}
          </div>
          <div className="date"> {moment(item?.createdAt).fromNow()}</div>
        </div> */}
      </div>
    </div>
  );
};
const StockCardComp = ({ item, key, handleActiveImg }) => {
  const userStore = useSelector((state) => state.userReducer);
  const baseUrl = `${window.location.protocol}//${window.location.host}`;

  const [userReacted, setUserReacted] = useState({});
  const [allReactions, setAllReactions] = useState([]);
  const [reactionCounts, setReactionCounts] = useState({});

  const addReaction = async (reactionType) => {
    try {
      let payload = {
        userId: userStore?.users?.user?._id,
        postId: item?._id,
        reactionType: reactionType,
      };
      if (userReacted?.reactionType == reactionType) {
        console.log("already reacted");
        return;
      }

      const resp = await api.stockReactionAdd(payload);
      console.log(resp);
      if (resp?.reaction?.userId) {
        // getReaction(item?._id);
        // Update the local state to reflect the new reaction count and the user's reaction
        setReactionCounts((prevCounts) => {
          const updatedCounts = { ...prevCounts };
          // If there's an existing reaction, decrement its count
          if (userReacted?.reactionType) {
            updatedCounts[userReacted?.reactionType] = Math.max(
              0,
              updatedCounts[userReacted?.reactionType] - 1
            );
          }
          // Increment the count for the new reaction type
          updatedCounts[reactionType] = (updatedCounts[reactionType] || 0) + 1;

          return updatedCounts;
        });
        setUserReacted(resp?.reaction);
      }
    } catch (error) {}
  };
  const deleteReaction = async (id, reactionType) => {
    try {
      let payload = {
        id: id,
        reactionType: reactionType,
        postId: item?._id,
      };
      console.log(payload);

      const resp = await api.stockReactionDelete(payload);
      console.log(resp);
      if (resp?.message == "Reaction deleted") {
        // Update the local state to reflect the removed reaction
        setReactionCounts((prevCounts) => {
          const updatedCounts = { ...prevCounts };
          if (updatedCounts[reactionType]) {
            updatedCounts[reactionType] -= 1;
          }
          return updatedCounts;
        });
        setUserReacted({});
        // getReaction(item?._id);
      }
    } catch (error) {
      console.log(error?.message);
    }
  };

  useEffect(() => {
    if (item?.reactionCount) {
      setReactionCounts(item?.reactionCount || {});
    }
  }, []);

  return (
    <div className="col-md-6 mb-3" key={key}>
      <div className="postCard">
        {item?.imageTitle && (
          <div
            className="imgDiv"
            onClick={() =>
              handleActiveImg(
                `${process.env.REACT_APP_S3URL}/img_${item.imageTitle}.${item.imageFormat}`
              )
            }
          >
            <img
              src={`${process.env.REACT_APP_S3URL}/img_${item.imageTitle}.${item.imageFormat}`}
              alt=""
            />
          </div>
        )}
        <div className="desc">
          <div
            dangerouslySetInnerHTML={{
              __html: item?.description,
            }}
          />
          <div className="date"> {moment(item?.createdAt).fromNow()}</div>
        </div>
        {/* <ReactionsMain
          post={item}
          addReaction={addReaction}
          deleteReaction={deleteReaction}
          // getReaction={getReaction}
          setUserReacted={setUserReacted}
          userReacted={userReacted}
        />
        <div className="statesRow">
          <div className="statsR">
            {Object.keys(reactionCounts).map(
              (reactionType) =>
                reactionCounts[reactionType] > 0 && (
                  <div className="stats">
                    {reactionType == "like" ||
                    reactionType == "love" ||
                    reactionType == "sad" ? (
                      <img
                        src={`${baseUrl}/emogis/${reactionType}.png`}
                        alt="icon"
                      />
                    ) : (
                      <img
                        src={`https://flagcdn.com/w20/${reactionType.toLowerCase()}.png`}
                        alt="icon"
                      />
                    )}
                    {reactionCounts[reactionType]}
                  </div>
                )
            )}
          </div>
          <div className="date"> {moment(item?.createdAt).fromNow()}</div>
        </div> */}
      </div>
    </div>
  );
};

const BroadCastcomp = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [noOfPost, setnoOfPost] = useState(0);
  const [loader, setLoader] = useState(true);
  const alert = useAlert();

  const getPosts = async () => {
    try {
      let payload = {
        page: page,
      };
      setLoader(true);
      const postData = await api.broadcastGet(payload);
      // console.log(postData);
      // setData([...data, ...postData.broadcast]);
      setData(postData.broadcast);

      setnoOfPost(postData.count);
      setLimit(postData.limit);
      setLoader(false);
    } catch (error) {
      alert.show(error?.message || "Something went wrong");
      console.log(error?.message);
      setLoader(false);
    }
  };
  const handleChange = (event, value) => {
    setPage(value);
    var scrollDiv = document.getElementById("cWidget").offsetTop;
    window.scrollTo({ top: scrollDiv, behavior: "smooth" });
  };
  useEffect(() => {
    getPosts();
  }, [page]);

  return (
    <div className="broadcastMain">
      {data.map((item, key) => (
        <div className="masgM" key={key}>
          <div className="date">{moment(item?.createdAt).fromNow()}</div>
          {item?.description && (
            <div className="msg">
              <div
                dangerouslySetInnerHTML={{
                  __html: item?.description,
                }}
              />
            </div>
          )}
          {item?.voice && (
            <div className="audio">
              <audio controls controlsList="nodownload">
                <source
                  src={`${process.env.REACT_APP_S3URLVoice}/${item.voice}`}
                />
                Audio element not supported by your browser.
              </audio>
            </div>
          )}
        </div>
      ))}
      {/* {limit * page < noOfPost && (
        <div className="showMoreBtn text-center mt-5">
          <button className="themeBtn" onClick={() => handleChange(page + 1)}>
            Show More
          </button>
        </div>
      )} */}
      <div className="d-flex justify-content-center">
        <Stack spacing={2}>
          <Pagination
            count={Math.ceil(noOfPost / limit)}
            page={page}
            onChange={handleChange}
          />
        </Stack>
      </div>
    </div>
  );
};

const Videocomp = () => {
  const userStore = useSelector((state) => state.userReducer);
  const alert = useAlert();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [noOfPost, setnoOfPost] = useState(0);
  const [loader, setLoader] = useState(true);
  const [selectedTab, setSelectedTab] = useState("beginner");

  const getPosts = async () => {
    try {
      let payload = {
        page: page,
        category: selectedTab,
        userId: userStore?.users?.user?._id,
      };
      setLoader(true);
      const postData = await api.videoGet(payload);
      // console.log(postData);
      // setData([...data, ...postData.video]);
      setData(postData.video);
      setnoOfPost(postData.count);
      setLimit(postData.limit);
      setLoader(false);
    } catch (error) {
      alert.show(error?.message || "Something went wrong");
      console.log(error?.message);
      setLoader(false);
    }
  };

  const handleChange = (event, value) => {
    setPage(value);
    var scrollDiv = document.getElementById("cWidget").offsetTop;
    window.scrollTo({ top: scrollDiv, behavior: "smooth" });
  };
  useEffect(() => {
    getPosts();
  }, [page]);

  useEffect(() => {
    // setPage(1);
    if (page == 1) {
      getPosts();
    } else {
      setPage(1);
    }
  }, [selectedTab]);

  return (
    <div className="broadcastMain">
      <div className="vTabs">
        <button
          className={selectedTab == "beginner" ? "active" : ""}
          onClick={() => setSelectedTab("beginner")}
        >
          Beginner
        </button>
        <button
          className={selectedTab == "advance" ? "active" : ""}
          onClick={() => setSelectedTab("advance")}
        >
          Advance
        </button>
      </div>
      {data.map((item, key) => (
        <PostCardVideo item={item} key={key} />
      ))}
      {/* {limit * page < noOfPost && (
        <div className="showMoreBtn text-center mt-5">
          <button className="themeBtn" onClick={() => handleChange(page + 1)}>
            Show More
          </button>
        </div>
      )} */}
      <div className="d-flex justify-content-center">
        <Stack spacing={2}>
          <Pagination
            count={Math.ceil(noOfPost / limit)}
            page={page}
            onChange={handleChange}
          />
        </Stack>
      </div>
    </div>
  );
};

const StocksComp = ({ handleActiveImg }) => {
  const userStore = useSelector((state) => state.userReducer);
  const alert = useAlert();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [noOfPost, setnoOfPost] = useState(0);
  const [loader, setLoader] = useState(true);

  const getPosts = async () => {
    try {
      let payload = {
        page: page,
        userId: userStore?.users?.user?._id,
      };
      setLoader(true);
      const postData = await api.stocksGet(payload);
      console.log(postData);
      // setData([...data, ...postData.post]);
      setData(postData.post);
      setnoOfPost(postData.count);
      setLimit(postData.limit);
      setLoader(false);
    } catch (error) {
      alert.show(error?.message || "Something went wrong");
      console.log(error?.message);
      setLoader(false);
    }
  };

  const handleChange = (event, value) => {
    setPage(value);
    var scrollDiv = document.getElementById("cWidget").offsetTop;
    window.scrollTo({ top: scrollDiv, behavior: "smooth" });
  };

  useEffect(() => {
    getPosts();
  }, [page]);

  return (
    <>
      <div className="userPostSec">
        {data.length == 0 && !loader && (
          <h3 className="text-center mt-4">No Data Available</h3>
        )}

        <div className="row">
          {data.map((item, key) => (
            <>
              <StockCardComp
                item={item}
                key={key}
                handleActiveImg={handleActiveImg}
              />
            </>
          ))}
        </div>
      </div>
      {/* {limit * page < noOfPost && (
        <div className="showMoreBtn text-center mt-5">
          <button
            className="themeBtn mx-auto"
            onClick={() => handleChange(page + 1)}
          >
            Show More
          </button>
        </div>
      )} */}
      <div className="d-flex justify-content-center">
        <Stack spacing={2}>
          <Pagination
            count={Math.ceil(noOfPost / limit)}
            page={page}
            onChange={handleChange}
          />
        </Stack>
      </div>
    </>
  );
};
const MarketComp = ({ handleActiveImg }) => {
  const userStore = useSelector((state) => state.userReducer);
  const alert = useAlert();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [noOfPost, setnoOfPost] = useState(0);
  const [loader, setLoader] = useState(true);

  const getPosts = async () => {
    try {
      let payload = {
        page: page,
        userId: userStore?.users?.user?._id,
      };
      setLoader(true);
      const postData = await api.martketUpdateGet(payload);
      // console.log(postData);
      // setData([...data, ...postData.post]); //showmore to merge previous data
      setData(postData.post);
      setnoOfPost(postData.count);
      setLimit(postData.limit);
      setLoader(false);
    } catch (error) {
      alert.show(error?.message || "Something went wrong");
      console.log(error?.message);
      setLoader(false);
    }
  };

  const handleChange = (event, value) => {
    setPage(value);
    var scrollDiv = document.getElementById("cWidget").offsetTop;
    window.scrollTo({ top: scrollDiv, behavior: "smooth" });
  };

  useEffect(() => {
    getPosts();
  }, [page]);

  return (
    <>
      <div className="userPostSec">
        {data.length == 0 && !loader && (
          <h3 className="text-center mt-4">No Data Available</h3>
        )}

        <div className="row">
          {data.map((item, key) => (
            <PostCardMarket
              item={item}
              key={key}
              handleActiveImg={handleActiveImg}
            />
          ))}
        </div>
      </div>
      {/* {limit * page < noOfPost && (
        <div className="showMoreBtn text-center mt-5">
          <button
            className="themeBtn mx-auto"
            onClick={() => handleChange(page + 1)}
          >
            Show More
          </button>
        </div>
      )} */}
      <div className="d-flex justify-content-center">
        <Stack spacing={2}>
          <Pagination
            count={Math.ceil(noOfPost / limit)}
            page={page}
            onChange={handleChange}
          />
        </Stack>
      </div>
    </>
  );
};

const PostCardMarket = ({ item, key, handleActiveImg }) => {
  const userStore = useSelector((state) => state.userReducer);
  const baseUrl = `${window.location.protocol}//${window.location.host}`;

  const [userReacted, setUserReacted] = useState({});
  const [allReactions, setAllReactions] = useState([]);

  const [reactionCounts, setReactionCounts] = useState({});

  const addReaction = async (reactionType) => {
    try {
      let payload = {
        userId: userStore?.users?.user?._id,
        postId: item?._id,
        reactionType: reactionType,
      };
      if (userReacted?.reactionType == reactionType) {
        console.log("already reacted");
        return;
      }

      const resp = await api.marketReactionAdd(payload);
      console.log(resp);
      if (resp?.reaction?.userId) {
        // getReaction(item?._id);
        // Update the local state to reflect the new reaction count and the user's reaction
        setReactionCounts((prevCounts) => {
          const updatedCounts = { ...prevCounts };
          // If there's an existing reaction, decrement its count
          if (userReacted?.reactionType) {
            updatedCounts[userReacted?.reactionType] = Math.max(
              0,
              updatedCounts[userReacted?.reactionType] - 1
            );
          }
          // Increment the count for the new reaction type
          updatedCounts[reactionType] = (updatedCounts[reactionType] || 0) + 1;

          return updatedCounts;
        });
        setUserReacted(resp?.reaction);
      }
    } catch (error) {}
  };
  const deleteReaction = async (id, reactionType) => {
    try {
      let payload = {
        id: id,
        reactionType: reactionType,
        postId: item?._id,
      };
      console.log(payload);

      const resp = await api.marketReactionDelete(payload);
      console.log(resp);
      if (resp?.message == "Reaction deleted") {
        // Update the local state to reflect the removed reaction
        setReactionCounts((prevCounts) => {
          const updatedCounts = { ...prevCounts };
          if (updatedCounts[reactionType]) {
            updatedCounts[reactionType] -= 1;
          }
          return updatedCounts;
        });
        setUserReacted({});
        // getReaction(item?._id);
      }
    } catch (error) {
      console.log(error?.message);
    }
  };

  useEffect(() => {
    if (item?.reactionCount) {
      setReactionCounts(item?.reactionCount || {});
    }
  }, []);

  return (
    <div className="col-12 mb-3" key={key}>
      <div className="postCard">
        {item?.imageTitle && (
          <div
            className="imgDiv"
            onClick={() =>
              handleActiveImg(
                `${process.env.REACT_APP_S3URL}/img_${item.imageTitle}.${item.imageFormat}`
              )
            }
          >
            <img
              src={`${process.env.REACT_APP_S3URL}/img_${item.imageTitle}.${item.imageFormat}`}
              alt=""
            />
          </div>
        )}
        <div className="py-2 iVideoPost">
          <div
            dangerouslySetInnerHTML={{
              __html: item?.video,
            }}
          />
        </div>
        <div className="desc">
          <div
            dangerouslySetInnerHTML={{
              __html: item?.description,
            }}
          />
          <div className="date"> {moment(item?.createdAt).fromNow()}</div>
        </div>
        {item?.voice && (
          <div className="audio my-2">
            <audio controls controlsList="nodownload" className="w-100">
              <source
                src={`${process.env.REACT_APP_S3URLVoice}/${item.voice}`}
              />
              Audio element not supported by your browser.
            </audio>
          </div>
        )}
        {/* <ReactionsMain
          post={item}
          addReaction={addReaction}
          deleteReaction={deleteReaction}
          setUserReacted={setUserReacted}
          userReacted={userReacted}
        />
        <div className="statesRow">
          <div className="statsR">
            {Object.keys(reactionCounts).map(
              (reactionType) =>
                reactionCounts[reactionType] > 0 && (
                  <div className="stats">
                    {reactionType == "like" ||
                    reactionType == "love" ||
                    reactionType == "sad" ? (
                      <img
                        src={`${baseUrl}/emogis/${reactionType}.png`}
                        alt="icon"
                      />
                    ) : (
                      <img
                        src={`https://flagcdn.com/w20/${reactionType.toLowerCase()}.png`}
                        alt="icon"
                      />
                    )}
                    {reactionCounts[reactionType]}
                  </div>
                )
            )}
          </div>
          <div className="date"> {moment(item?.createdAt).fromNow()}</div>
        </div> */}
      </div>
    </div>
  );
};
const PostCardVideo = ({ item, key }) => {
  const userStore = useSelector((state) => state.userReducer);
  const baseUrl = `${window.location.protocol}//${window.location.host}`;

  const [userReacted, setUserReacted] = useState({});
  const [allReactions, setAllReactions] = useState([]);

  const [reactionCounts, setReactionCounts] = useState({});

  const addReaction = async (reactionType) => {
    try {
      let payload = {
        userId: userStore?.users?.user?._id,
        postId: item?._id,
        reactionType: reactionType,
      };
      if (userReacted?.reactionType == reactionType) {
        console.log("already reacted");
        return;
      }

      const resp = await api.educationReactionAdd(payload);
      console.log(resp);
      if (resp?.reaction?.userId) {
        // getReaction(item?._id);
        // Update the local state to reflect the new reaction count and the user's reaction
        setReactionCounts((prevCounts) => {
          const updatedCounts = { ...prevCounts };
          // If there's an existing reaction, decrement its count
          if (userReacted?.reactionType) {
            updatedCounts[userReacted?.reactionType] = Math.max(
              0,
              updatedCounts[userReacted?.reactionType] - 1
            );
          }
          // Increment the count for the new reaction type
          updatedCounts[reactionType] = (updatedCounts[reactionType] || 0) + 1;

          return updatedCounts;
        });
        setUserReacted(resp?.reaction);
      }
    } catch (error) {}
  };
  const deleteReaction = async (id, reactionType) => {
    try {
      let payload = {
        id: id,
        reactionType: reactionType,
        postId: item?._id,
      };
      console.log(payload);

      const resp = await api.educationReactionDelete(payload);
      console.log(resp);
      if (resp?.message == "Reaction deleted") {
        // Update the local state to reflect the removed reaction
        setReactionCounts((prevCounts) => {
          const updatedCounts = { ...prevCounts };
          if (updatedCounts[reactionType]) {
            updatedCounts[reactionType] -= 1;
          }
          return updatedCounts;
        });
        setUserReacted({});
        // getReaction(item?._id);
      }
    } catch (error) {
      console.log(error?.message);
    }
  };

  useEffect(() => {
    if (item?.reactionCount) {
      setReactionCounts(item?.reactionCount || {});
    }
  }, []);

  return (
    <div className="masgM" key={key}>
      {item?.video && (
        <div className="msg">
          <div
            dangerouslySetInnerHTML={{
              __html: item?.video,
            }}
          />
          {item?.title && <div className="title">{item?.title}</div>}
          <div className="date"> {moment(item?.createdAt).fromNow()}</div>
        </div>
      )}
      {/* <ReactionsMain
        post={item}
        addReaction={addReaction}
        deleteReaction={deleteReaction}
        setUserReacted={setUserReacted}
        userReacted={userReacted}
      />
      <div className="statesRow">
        <div className="statsR">
          {Object.keys(reactionCounts).map(
            (reactionType) =>
              reactionCounts[reactionType] > 0 && (
                <div className="stats">
                  {reactionType == "like" ||
                  reactionType == "love" ||
                  reactionType == "sad" ? (
                    <img
                      src={`${baseUrl}/emogis/${reactionType}.png`}
                      alt="icon"
                    />
                  ) : (
                    <img
                      src={`https://flagcdn.com/w20/${reactionType.toLowerCase()}.png`}
                      alt="icon"
                    />
                  )}
                  {reactionCounts[reactionType]}
                </div>
              )
          )}
        </div>
        <div className="date"> {moment(item?.createdAt).fromNow()}</div>
      </div> */}
    </div>
  );
};

const Storycomp = () => {
  const storylyRef = useRef();
  useLayoutEffect(() => {
    storylyRef.current.init({
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NfaWQiOjE0MDEwLCJhcHBfaWQiOjIxNjAwLCJpbnNfaWQiOjI0MzQyfQ.Q8zpWP6OhkeiyW3_YMbrLyLxxpXKcdgKHd89p8f-bCY",
    });
  }, []);
  return (
    <>
      <div className="mt-4">
        <storyly-web ref={storylyRef} />
      </div>
    </>
  );
};
