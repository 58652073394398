import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import ReactQuill from "react-quill";
import api from "../../api";
import Logo from "../../images/logo-2.png";
import add from "../../images/add.png";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import MicRecorder from "mic-recorder-to-mp3";

const Mp3Recorder = new MicRecorder({
  bitRate: 64,
  prefix: "data:audio/wav;base64,",
});

export function AddMarket({ getPosts, setShow }) {
  const adminStore = useSelector((state) => state.adminReducer);

  const [value, setValue] = useState("");
  const [title, setTitle] = useState("");
  const [loader, setLoader] = useState(false);
  const [selectedImg, setSelectedImg] = useState(null);
  const [inputData, setInputData] = useState({
    description: "",
    img: "",
    format: "",
  });
  const [isRecording, setIsRecording] = useState(false);
  const [blobURL, setBlobURL] = useState("");
  const [blob, setBlob] = useState("");
  const [isBlocked, setIsBlocked] = useState(false);

  const handleSubmit = async () => {
    try {
      if (inputData.description) {
        setLoader(true);
        // let payload = {
        //   _id: adminStore.admin.admin._id,
        //   description: inputData?.description,
        //   video: inputData?.video || "",
        //   imageTitle: inputData.img || "",
        //   imageFormat: inputData.format || "",
        // };
        const formData = new FormData();
        formData.append("_id", adminStore.admin.admin._id);
        formData.append("description", inputData?.description);
        formData.append("video", inputData?.video || "");
        formData.append("imageTitle", inputData.img || "");
        formData.append("imageFormat", inputData.format || "");
        if (blob?.size) {
          formData.append("voice", blob, "mp3.mp3");
        }
        // console.log(payload);
        const signalResponse = await api.martketUpdateCreate(formData);
        if (signalResponse?.success) {
          alert("Market update added successfully");
          setInputData({
            description: "",
            img: "",
            format: "",
          });
          setBlobURL("");
          setBlob("");
          setLoader(false);
          setSelectedImg(null);
          getPosts();
          setShow(false);
          // sendNotification()
        } else {
          setLoader(false);
          alert("Something went wrong");
        }
      } else {
        setLoader(false);
        alert("Please insert description");
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      alert("Something went wrong");
    }
  };

  var Editor = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const onFileChange = async (e) => {
    try {
      setLoader(true);

      const formData = new FormData();
      formData.append("image", e.target.files[0], e.target.files[0].name);
      const uploadRes = await api.uploadFile(formData);

      setSelectedImg(
        `${process.env.REACT_APP_S3URL}/img_${uploadRes[0]}.${uploadRes[1]}`
      );
      setInputData({
        ...inputData,
        img: uploadRes[0],
        format: uploadRes[1],
      });
      setLoader(false);
    } catch (e) {
      alert("File upload failed");
      setLoader(false);
      console.log(e);
    }
  };

  const handleChange = (e) => {
    // console.log(e);
    setInputData({ ...inputData, description: e });
  };

  const start = () => {
    if (isBlocked) {
      alert("Permission Denied");
    } else {
      Mp3Recorder.start()
        .then(() => {
          setIsRecording(true);
        })
        .catch((e) => console.error(e));
    }
  };
  const stop = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        const binaryString = btoa(blobURL);
        console.log(binaryString);
        console.log(blob);
        console.log(buffer);
        setIsRecording(false);
        setBlobURL(blobURL);
        setBlob(blob);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    navigator.getUserMedia(
      { audio: true },
      () => {
        console.log("Permission Granted");
        setIsBlocked(false);
      },
      () => {
        console.log("Permission Denied");
        setIsBlocked(true);
      }
    );
  }, []);

  return (
    <>
      {/* Begin Page Content */}
      <div className="container-fluid">
        {/* Content Row */}
        <div className="row">
          {/* Area Chart */}
          <div className="col">
            <div className="row mx-0 drivers-row">
              <div className="col-xl-6 col-md-7  d-flex justify-content-between pr-0">
                <h4 className="color-dark fs-17 dib font-weight-bold mr-5">
                  Add Market Update
                </h4>
              </div>
            </div>
            <div className="card sortby-box br-10 border-transparent mt-3">
              <div className="card-header br-10  bg-white border-transparent py-3 ">
                {/* <div className="form-group mb-3">
                                <input type="text" className='form-control' onChange={(e)=>setTitle(e.target.value)} placeholder='Title...' />
                              </div> */}
                <div className="descMainPost mb-3">
                  <ReactQuill
                    placeholder="Add description"
                    modules={Editor}
                    theme="snow"
                    value={inputData.description}
                    onChange={handleChange}
                  />
                </div>
                <div className="voiceUploadRow">
                  <audio src={blobURL} controls="controls" />
                  {isRecording ? (
                    <button onClick={stop} disabled={!isRecording}>
                      <i className="fa-solid fa-microphone-lines-slash"></i>
                    </button>
                  ) : (
                    <button onClick={start} disabled={isRecording}>
                      <i className="fa-solid fa-microphone-lines"></i>
                    </button>
                  )}
                </div>
                {isRecording && (
                  <h4 className="text-center d-flex ai-center jc-center my-3">
                    Recording <div className="dotLoader ms-4"></div>
                  </h4>
                )}
                <div className="form-group mb-3">
                  <textarea
                    name="video"
                    id=""
                    className="form-control"
                    onChange={(e) =>
                      setInputData({ ...inputData, video: e.target.value })
                    }
                    placeholder="Add Video Iframe"
                  ></textarea>
                </div>
                <div className="form-group mb-3 fileUpload-modal">
                  <label>
                    <input
                      name="file"
                      onChange={onFileChange}
                      accept="audio/*,video/*,image/*"
                      type={"file"}
                    />
                    {selectedImg == null && <img src={add} />}
                    {selectedImg && (
                      <img className="img_sel" src={selectedImg} />
                    )}
                  </label>
                </div>
                <div className="sendMailBtn mt-3">
                  <button onClick={handleSubmit} className="btn-dark">
                    Submit
                  </button>
                </div>
              </div>
            </div>
            {/* table */}
          </div>
        </div>
      </div>
      {/* /.container-fluid */}
      {loader && (
        <div className="seekoLoader">
          <div className="loader">
            <img src={Logo} alt="loader" />
          </div>
        </div>
      )}
    </>
  );
}
