import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "../../api";
import { useAlert } from "react-alert";
import userAction from "../../redux/users/action";
import axios from "axios";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import moment from "moment";

export const Checkout = ({ SubsData }) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { setUser } = userAction;
  const userStore = useSelector((state) => state.userReducer);
  const [loader, setLoader] = useState(true);
  const [isSubscription, setIsSubscription] = useState(false);
  // const [SubsData, setSubsData] = useState({});

  const onStripePay = async () => {
    try {
      setLoader(true);
      if (!userStore?.users?.user?._id) {
        alert.show("User not found");
      }
      const payload = {
        _id: userStore?.users?.user?._id,
        item: 1,
        months: 1,
      };
      const stripeRes = await api.createPayment(payload);

      if (!stripeRes?.link) {
        throw new Error("Something went wrong");
      }

      window.open(stripeRes?.link, "_self");
      setLoader(false);
    } catch (e) {
      alert.show(e.message);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (userStore?.users?.user?._id) {
      // checkSubs();
    }
    setLoader(false);
    setIsSubscription(SubsData?.isSubscription || false);
  }, [userStore?.users, SubsData]);

  return (
    <>
      <div className="contact-widget-area pb-70">
        <div className="contact-widget-area-inner">
          <div className="section-title  text-center mb-45">
            <h2 className="">WE ARE AN EDUCATIONAL PLATFORM</h2>
          </div>
          <div className="investContent mx-auto mb-4">
            <div className="mb-3">
              <p className="mb-0">
                🔹 Get exclusive access to our current investments in crypto
                projects.
              </p>
              <p className="mb-0">
                🔹 Track our portfolio performance and see what’s working.
              </p>
            </div>
            <div className="mb-3">
              <p className="mb-0">🔍 Where We Are Going to Invest</p>
              <p className="mb-0">
                🔹 Stay ahead with our future investment plans before anyone
                else.
              </p>
              <p>
                🔹 Leverage our in-depth market analysis and research-backed
                decisions.
              </p>
            </div>
            <div className="mb-3">
              <p className="mb-0">📊 What You Get:</p>
              <p className="mb-0">
                ✅ Insider insights into top crypto projects.
              </p>
              <p className="mb-0">
                ✅ Real-time investment strategies from experienced traders.
              </p>
              <p className="mb-0">
                ✅ Exclusive research reports on market trends and
                opportunities.
              </p>
              <p className="mb-0">
                ✅ Community discussions and expert opinions.
              </p>
            </div>
            <div className="mb-3">
              <p className="mb-0">
                💳 Subscription Details: <b>10$ Monthly</b>
              </p>
              <p className="mb-0">
                🔁 Monthly Recurring Subscription – You’ll be charged
                automatically after <b>30 Days</b> completed.{" "}
              </p>
            </div>
          </div>
          <div className="checkCard contact-form signup-form">
            <div className="form-group">
              <input
                className="form-control"
                type="email"
                value={userStore?.users?.user?.email}
                disabled
              />
            </div>
            <div className="text-center">
              {isSubscription && !loader ? (
                <>
                  <button className="themeBtn w-100">Already Subscribed</button>
                </>
              ) : loader ? (
                <button className="themeBtn w-100">
                  <i
                    className="fa fa-spinner fa-pulse mx-2 mb-1"
                    style={{ position: "relative", top: "-0px" }}
                  ></i>
                  loading
                </button>
              ) : (
                <button className="themeBtn w-100" onClick={onStripePay}>
                  PAY NOW
                </button>
              )}
              {/* <Link className="themeBtn w-100" to="/dashboard">
                Click To Show
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
