import React, { useEffect } from "react";

export const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
      <div className="termsPage">
        <h1>LEGAL DISCLAIMER & TERMS OF USE/CONDITIONS</h1>
        <p className="mb-3">
          <b>Last Updated:</b> 20.03.2025{" "}
        </p>
        <p className="mb-3">
          Welcome to www.waqarzaka.net (the "Website"). By accessing or using
          this Website, you agree to the following disclaimers, terms, and
          policies. If you do not agree with any part of this disclosure, please
          discontinue use immediately.{" "}
        </p>

        <h2>GENERAL DISCLAIMER</h2>
        <p className="mb-3">
          This Website is for educational and informational purposes only. We do
          not provide financial, investment, legal, or tax advice. Any content,
          materials, or courses available on this platform should not be
          construed as professional financial guidance. Before making any
          trading or investment decisions, consult with a licensed financial
          advisor, attorney, or tax professional to assess your individual
          circumstances.
        </p>
        <h2>NO FINANCIAL OR INVESTMENT ADVICE</h2>
        <p className="mb-3">
          We are not financial advisors, brokers, or investment professionals.
          No content on this Website constitutes financial, investment, or legal
          advice. Any financial or trading decisions you make based on our
          content are at your own risk. Past performance of financial markets,
          cryptocurrencies, or trading strategies is not indicative of future
          results.
        </p>
        <h2>Risk Disclosure</h2>

        <p className="mb-3">
          Trading and investing involve substantial risk, including potential
          loss of your entire investment. The cryptocurrency and forex markets
          are highly volatile and not suitable for all investors. You should
          only trade or invest money that you can afford to lose.
        </p>
        <h2>Personal Responsibility</h2>
        <p className="mb-3">
          By using this Website, you acknowledge that you are solely responsible
          for your financial decisions. We are not liable for any financial
          losses, damages, or negative outcomes from using our educational
          materials
        </p>
        <p className="mb-3">
          We do not guarantee any specific financial outcomes, earnings, or
          success from using our content. Any testimonials, success stories, or
          examples are exceptional cases and not typical results. Your personal
          financial success depends on various factors outside our control,
          including market conditions, experience, and risk tolerance.
        </p>
        <p className="mb-3">
          All information is provided "as is" and "as available", with no
          warranties of accuracy, completeness, or reliability. We strive to
          provide up-to-date information, but we do not guarantee that all
          content is free from errors, omissions, or outdated material. By
          accessing this Website, you agree that we, our owners, employees, and
          affiliates are not responsible for any financial losses resulting from
          your reliance on our content.
        </p>
        <p className="mb-3">
          This Website may include links to third-party websites, sponsored
          content, or affiliate promotions.
        </p>
        <p className="mb-3">
          We do not own, control, or guarantee the accuracy, reliability, or
          legitimacy of third-party services. If you engage with third-party
          services through our links, you do so at your own risk, and we are not
          responsible for any issues or disputes arising from those engagements.
          We may earn a commission from affiliate links, but this does not
          influence our content recommendations.
        </p>
        <p className="mb-3">
          By using this Website, you acknowledge that you have read and
          understood our Privacy Policy, which outlines:
        </p>
        <p className="mt-0">
          What personal data we collect (e.g., email, payment details,
          analytics).
        </p>
        <p className="mt-0">How we use and store data.</p>
        <p className="mt-0">Third-party sharing policies</p>
        <p className="mt-0">User rights, including data deletion requests.</p>
        <p className="mt-0">
          For more details, refer to our Privacy Policy page.
        </p>

        <p className="mb-3">
          All sales of digital products, including courses, are final and
          non-refundable, except in cases of accidental duplicate purchases
        </p>
        <p className="mb-3">
          Users may not copy, distribute, or reproduce any content without
          written permission. Unauthorized use of our content may result in
          legal action
        </p>
        <p className="mb-3">
          Users must not engage in fraud, harassment, illegal activities, or
          violation of these terms. We reserve the right to suspend or terminate
          any user account that violates these terms
        </p>
        <p className="">
          To the fullest extent permitted by law, we shall not be liable for any
          direct, indirect, incidental, consequential, or special damages
          arising from:
        </p>
        <p>Use of or inability to use our Website.</p>
        <p>Errors or inaccuracies in content.</p>
        <p>Third-party services or external links.</p>
        <p>Loss of profits, revenue, or financial losses.</p>
        <p className="mt-3">
          We reserve the right to modify or update this legal disclosure at any
          time without prior notice. It is your responsibility to review this
          page periodically for any changes.
        </p>
      </div>
    </div>
  );
};
