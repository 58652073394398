import React, { useState } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";

export const CokieModal = () => {
  const [showMore, setShowMore] = useState(false);
  const handleCloseMore = () => setShowMore(false);
  const handleShowMore = () => setShowMore(true);

  const accept = () => {
    localStorage.setItem("disclaimer", true);
    handleCloseMore();
  };

  useEffect(() => {
    let check = localStorage.getItem("disclaimer");

    if (check == "true") {
    } else {
      handleShowMore();
    }
  }, []);

  return (
    <Modal size="lg" show={showMore} centered>
      <Modal.Body>
        <div className="otpModalMain broadcastModal">
          {/* <div className="closeIcon" onClick={handleCloseMore}>
            <i className="fa-solid fa-xmark" />
          </div> */}
          <h5 className="text-center">
            GLOBAL LEGAL DISCLAIMER & LIABILITY WAIVER
          </h5>

          <div className="showMoreModalM">
            <div className="showMoreModal">
              <p className="desc1 my-3">
                By accessing and using www.waqarzaka.net, engaging with its
                content, or participating in any live or recorded sessions, you
                confirm that you have read, understood, and fully agree to the
                following terms. If you do not accept these terms, you must
                immediately exit the website and discontinue use.
              </p>
              <p className="desc1">
                1. No Financial, Investment, or Legal Advice
              </p>
              <ul>
                <li>
                  Waqar Zaka, his company, employees, affiliates, and associated
                  entities are NOT financial advisors, investment professionals,
                  tax consultants, or legal advisors.
                </li>
                <li>
                  All information shared via www.waqarzaka.net, social media,
                  webinars, or any other platform is strictly educational and
                  informational.
                </li>
                <li>
                  No content on this platform should be considered financial,
                  investment, tax, or legal advice.
                </li>
                <li>
                  Any decision to trade, invest, or take financial action is
                  entirely the user's own responsibility.
                </li>
              </ul>

              <p className="desc1 mt-3">
                2. No Guarantees & Acknowledgment of Risk
              </p>
              <ul>
                <li>
                  Trading and investing involve high risk, including but not
                  limited to complete financial loss, liquidation, or
                  bankruptcy.
                </li>
                <li>
                  Past performance of any strategy, content, or individual does
                  not guarantee future success.
                </li>
                <li>
                  Waqar Zaka and his company do NOT guarantee profits, financial
                  gains, or positive results from any strategy or trade.
                </li>
                <li>
                  Users explicitly agree that any financial loss is 100% their
                  own responsibility.
                </li>
              </ul>

              <p className="desc1 mt-3">3. No Liability for Copying Trades</p>

              <ul>
                <li>
                  Any user who chooses to copy, mirror, or replicate trades or
                  strategies shown on www.waqarzaka.net does so at their own
                  risk.
                </li>
                <li>
                  Waqar Zaka and his company bear NO responsibility for any
                  losses or liquidations incurred by users who copy trades.
                </li>
                <li>
                  If a user chooses to copy trades shown on live sessions,
                  videos, or posts, they acknowledge that they are acting
                  independently.
                </li>
              </ul>

              <p className="desc1 mt-3">
                4. Global Legal Jurisdiction & Legal Protections
              </p>
              <ul>
                <li>
                  All legal matters will be governed by the jurisdiction where
                  Waqar Zaka's business is officially registered.
                </li>
                <li>
                  Users waive the right to file lawsuits or claims in any
                  country outside of this agreement.
                </li>
              </ul>

              <p className="desc1 mt-3">
                5. Legal Protections Under International & Country-Specific Laws
              </p>
              <p className="desc1">Pakistan (Applicable to Pakistani Users)</p>
              <ul>
                <li>
                  The Securities Act, 2015: Waqar Zaka is NOT registered with
                  SECP; thus, his content is not legally considered financial
                  advice.
                </li>
                <li>
                  Pakistan Penal Code, Section 415: No fraud or deception claims
                  are legally valid.
                </li>
                <li>
                  Prevention of Electronic Crimes Act (PECA), 2016: False
                  accusations or defamatory remarks against Waqar Zaka may
                  result in legal action.
                </li>
              </ul>

              <p className="desc1 mt-3">
                United States (Applicable to U.S. Users)
              </p>
              <ul>
                <li>
                  U.S. Securities Act of 1933 & Investment Advisers Act of 1940:
                  Waqar Zaka is NOT an SEC-registered advisor
                </li>
                <li>
                  FTC Disclosure Laws: No legal grounds for false advertising
                  claims.
                </li>
              </ul>

              <p className="desc1 mt-3">
                United Kingdom (Applicable to U.K. Users)
              </p>
              <ul>
                <li>
                  Financial Services and Markets Act 2000 (FSMA): Waqar Zaka is
                  NOT FCA-authorized.
                </li>
                <li>
                  U.K. Defamation Act 2013: False accusations may face legal
                  consequences.
                </li>
              </ul>

              <p className="desc1 mt-3">
                European Union (Applicable to E.U. Users)
              </p>
              <ul>
                <li>
                  Markets in Financial Instruments Directive (MiFID II): Waqar
                  Zaka is NOT a licensed financial advisor in the EU
                </li>
                <li>
                  E.U. Consumer Protection Law: No consumer claims can be made
                  against him.
                </li>
              </ul>

              <p className="desc1 mt-3">
                Australia (Applicable to Australian Users)
              </p>
              <ul>
                <li>
                  Corporations Act 2001 (ASIC Regulations): Waqar Zaka is NOT
                  ASIC-registered.
                </li>
                <li>
                  Australian Consumer Law (ACL): Users cannot file complaints
                  under misleading conduct laws.
                </li>
              </ul>

              <p className="desc1 mt-3">India (Applicable to Indian Users)</p>
              <ul>
                <li>SEBI Act, 1992: Waqar Zaka is NOT SEBI-registered.</li>
                <li>
                  Information Technology Act, 2000: False legal accusations
                  against Waqar Zaka may have consequences.
                </li>
              </ul>

              <p className="desc1 mt-3">
                6. Waiver of Rights & Acceptance of Terms
              </p>
              <p className="desc1">By proceeding, you confirm that:</p>
              <ul>
                <li>
                  You understand and accept all risks associated with trading,
                  investing, and financial decision-making.
                </li>
                <li>
                  You waive all rights to hold Waqar Zaka responsible for any
                  financial losses.
                </li>
                <li>
                  You agree that any legal disputes must be resolved in Waqar
                  Zaka's business jurisdiction.
                </li>
              </ul>

              <p className="desc1 sm-text mt-3">
                7. Trading and investing involve risk, and past performance is
                not indicative of future results. WaqarZaka Platform provides
                educational content only and does not guarantee any financial
                outcomes. We are not responsible for any financial losses
                incurred based on information provided on our platform.
              </p>
              <p className="desc1 sm-text mt-3">
                8. Team Waqar Zaka may mention or recommend certain trading
                platforms, tools, or exchanges for educational purposes.
                However, this does not constitute an endorsement or guarantee of
                their services. Users should independently verify the
                reliability and suitability of any platform before use.
              </p>
              <p className="desc1 sm-text mt-3">
                9. WaqarZaka and its team are not responsible for any financial
                losses, damages, or decisions made based on the information
                provided on our platform. All content is for educational
                purposes only, and users should exercise due diligence before
                engaging in any trading or investment activities.
              </p>

              <p className="desc1 mt-3">
                If you disagree with any part of this disclaimer, you must
                immediately exit the website and discontinue use.
              </p>
            </div>
            <div className="mt-4">
              <button className="themeBtn mx-auto" onClick={accept}>
                I Understand
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
