import { useState } from "react";
import { useAlert } from "react-alert";
import { Modal } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import api from "../api";

export const OtpModal = ({ show, setShow, email }) => {
  const [otp, setotp] = useState("");
  const alert = useAlert();
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChangeOtp = (otp) => {
    setotp(otp);
  };

  const handleOtp = async (e) => {
    if (otp.length < 6) {
      alert("Please fill OTP code");
    } else {
      verifyOTP(otp);
    }
  };

  const verifyOTP = async (otpCode) => {
    try {
      const payload = {
        email: email,
        otp: otpCode,
      };

      const response = await api.verifyEmail(payload);
      // console.log(response);
      if (response?.success) {
        navigate("/login");
        alert.show("Otp verified now you can login");
        handleClose();
      } else {
        alert.show("Invalid OTP");
      }
    } catch (error) {
      alert.show("Something went wrong, try later");
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body>
        <div className="otpModalMain">
          <div className="closeIcon" onClick={handleClose}>
            <i className="fa-solid fa-xmark" />
          </div>

          <h2 className="title">Email Verification</h2>
          <div className="desc">
            we have sent you a code to your email address. <br />
            Enter the 6 digit code, received by email
          </div>
          <div className="otpInputMain">
            <OtpInput
              value={otp}
              onChange={handleChangeOtp}
              numInputs={6}
              isInputNum={true}
              placeholder="----"
              separator={<span>-</span>}
            />
          </div>
          {/* <div className="resend">
            Did not receive a code? <span>Resend code</span>
          </div> */}
          <div className="text-center mt-4">
            <button onClick={handleOtp} className="themeBtn w-100">
              {" "}
              Verify{" "}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
