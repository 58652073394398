import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

export const MonthlyModal = ({ showMonthly, handleCloseMonthly }) => {
  return (
    <Modal show={showMonthly} onHide={handleCloseMonthly} centered>
      <Modal.Body>
        <div className="otpModalMain">
          <div className="closeIcon" onClick={handleCloseMonthly}>
            <i className="fa-solid fa-xmark" />
          </div>

          {/* <h2 className="title color-dark mb-0">Follow these steps.</h2>
      <div className="imgDiv">
        <img className='img-fluid' src={monthlyGroup} alt="" />
      </div> */}
          <h4 className=" color-dark mb-0 mt-4 text-center">
            🚨 Monthly Membership Closed! 🚨
          </h4>
          {/* <div className="homeVideoM py-4">
           
            <iframe
              className="w-100"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/4t_CkcCq4Iw?si=pzvqZsSv3TtK8U77&autoplay=1&mute=1"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div> */}
          <div className="text-center">
            {/* <a
              className="themeBtn"
              href="https://linktr.ee/waqarzaka"
              target="_blank"
              rel="noopener noreferrer"
            >
              Joining Link
            </a> */}
            <div className="desc1 my-5">
              Our monthly membership for WZ.NET is now closed. We appreciate
              your interest and support! Stay tuned—we will notify you as soon
              as the next membership window opens.
            </div>
            <Link to="/signup" className="themeBtn">
              Register Now for updates
            </Link>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
