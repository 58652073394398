import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import copy from "copy-to-clipboard";
import { useAlert } from "react-alert";
import api from "../../api";
import CountrySelect from "../countrySelect";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { Link } from "react-router-dom";
import { useEffect } from "react";
export const AnnualModal = ({
  show,
  handleClose,
  formSubmitted,
  setformSubmitted,
}) => {
  const [copyText, setCopyText] = useState("Copy to clipboard");
  const [loader, setLoader] = useState(false);
  const alert = useAlert();
  const [data, setData] = useState({
    email: "",
  });
  const [phoneNum, setPhoneNum] = useState("");

  const handleCopy = (text) => {
    copy(text);
    setCopyText("Copied");
    setTimeout(() => {
      setCopyText("Copy to clipboard");
    }, 3000);
  };

  const onTextChange = (e, type) => {
    try {
      if (e.label) {
        setData({ ...data, country: e.label });
      } else if (e.target.name == "terms") {
        setData({ ...data, terms: e.target.checked });
      } else {
        setData({ ...data, [e.target.name]: e.target.value });
      }
    } catch (error) {}
  };
  const validators = () => {
    let regexEmail =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z]+)*$/;
    // if (!data.firstName) {
    //   return "First name is required";
    // }
    if (!data.name) {
      return "User name is required";
    }
    // if (hasWhiteSpace(data.name)) {
    //   return "Space are not allowed in username";
    // }
    if (!data.email) {
      return "Email is required";
    }
    // if (!data.phone) {
    //   return "Phone is required";
    // }
    // if (isNaN(data.phone)) {
    //   return "Phone is not correctly formatted";
    // }
    if (data.email && !regexEmail.test(data.email)) {
      return "Email is not correctly formatted";
    }

    if (!phoneNum) {
      return "Phone is required";
    }
    if (phoneNum.length > 15) {
      return "Phone must be less than 15 number";
    }
    if (!data.country) {
      return "Country is required";
    }
    if (!data.day) {
      return "Day of birth is required";
    }
    if (data.day < 1 || data.day > 31) {
      return "Day must be greater than 1 and less than 31";
    }
    if (!data.month) {
      return "Month of birth is required";
    }
    if (!data.year) {
      return "Year of birth is required";
    }
    if (data.year < 1900 && data.year < 2024) {
      return "Year must be greater than 1900 and less than current year";
    }
    if (!data?.terms) {
      return "Please read and  accept terms and conditions";
    }

    return false;
  };

  const register = async (e) => {
    e.preventDefault();
    setLoader(true);

    try {
      const validatorsResponse = validators();

      if (validatorsResponse) {
        setLoader(false);
        return alert.show(validatorsResponse);
      }

      const payload = {
        name: data.name,
        email: data.email,
        dob: `${data?.year}-${data?.month}-${data?.day}`,
        country: data.country,
        // phone: phoneNum,
      };
      // console.log(payload);
      const res = await api.addYearlyContactForm(payload);
      if (res?.success) {
        setLoader(false);
        setData({});
        setformSubmitted(true);
      } else {
        alert.show("Something went wrong, try later");
      }
    } catch (e) {
      console.log(e.message);
      alert.error(e?.message || "Something went wrong, try later");
      setLoader(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered className="videoM">
      <Modal.Body>
        <div className="otpModalMain">
          <div className="closeIcon" onClick={handleClose}>
            <i className="fa-solid fa-xmark" />
          </div>
          <div className="homeVideoM mt-4">
            <iframe
              className="w-100"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/cZheCh813xE?si=8PfVPJqiepBr6UQY&autoplay=1&mute=1"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
            <h3 className="color-dark text-center mb-4 mt-3">
              Annual Group Membership is Now Closed 🚫
            </h3>
            <div className="desc1 text-center">
              Thank you for your interest! Please visit us again for future
              updates and openings.
            </div>
          </div>
          {/* {formSubmitted ? (
            <>
              <h5 className="color-dark text-center mb-4 mt-3">
                Thank You for Your Submission!
              </h5>
              <div className="desc1 mb-3">
                We have received your request to join the Annual Group. You
                should have received an email with payment instructions — please
                check your inbox and spam folder. Kindly follow the payment
                steps mentioned in that email.
              </div>
              <div className="noteTitle">📌 Important Note:</div>
              <div className="note">
                Be cautious of any other emails claiming to be from us, and
                never share your payment details with anyone else.
              </div>

              <h6 className="mb-0">Best Regards, </h6>
              <h6>Team WZ </h6>
            </>
          ) : (
            <>
              <div className="contact-form signup-form pt-2">
                <h3 className="color-dark text-center mb-4 mt-3">
                  Annual Group Membership – Contact Submission Form
                </h3>
                <h6
                  className="color-dark text-center mb-4 mt-3"
                  style={{ fontSize: 18 }}
                >
                  Join the Exclusive Annual Group!
                </h6>
                <div className="desc1 mb-3">
                  To become a member, a yearly <b>$1,250 fee</b> is required.
                  Once you submit this form, our team will contact you with
                  payment details and further instructions.{" "}
                </div>
                <h6>Exclusive Benefits Await You! </h6>
                <div className="desc1">
                  ✅ Access to premium content & insights
                </div>
                <div className="desc1">
                  ✅ Exclusive networking opportunities{" "}
                </div>
                <div className="desc1">✅ VIP group discussions & events </div>
                <form
                  noValidate
                  id="contactForm"
                  className="ng-invalid ng-dirty ng-touched mt-4"
                >
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          onChange={(e) => onTextChange(e)}
                          type="text"
                          name="name"
                          id="name"
                          required
                          placeholder="Name"
                          className="form-control ng-dirty ng-invalid ng-touched"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          onChange={(e) => onTextChange(e)}
                          type="text"
                          name="email"
                          id="email"
                          required
                          placeholder="Email"
                          className="form-control ng-dirty ng-invalid ng-touched"
                        />
                      </div>
                    </div>

                    <div className="col-12 mb-2">
                      <label className="ms-2">Enter Your Birth Date</label>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <input
                          onChange={(e) => onTextChange(e)}
                          type="number"
                          name="day"
                          required
                          placeholder="DD"
                          className="form-control ng-dirty ng-invalid ng-touched"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group position-relative">
                        <i className="fa-solid fa-caret-down  arrowDownIco" />
                        <select
                          onChange={(e) => onTextChange(e)}
                          name="month"
                          placeholder="Month"
                          id=""
                          className="form-control ng-dirty "
                        >
                          <option value="">Select Month</option>
                          <option value="01">Jan</option>
                          <option value="02">Feb</option>
                          <option value="03">Mar</option>
                          <option value="04">Apr</option>
                          <option value="04">May</option>
                          <option value="06">Jun</option>
                          <option value="07">Jul</option>
                          <option value="08">Aug</option>
                          <option value="09">Sep</option>
                          <option value="10">Oct</option>
                          <option value="11">Nov</option>
                          <option value="12">Dec</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <input
                          onChange={(e) => onTextChange(e)}
                          type="number"
                          name="year"
                          id="email"
                          required
                          placeholder="YYYY"
                          className="form-control ng-dirty ng-invalid ng-touched"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <CountrySelect onTextChange={onTextChange} />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group phone">
                        <PhoneInput
                          className="form-control"
                          international
                          countryCallingCodeEditable={false}
                          value={phoneNum}
                          onChange={setPhoneNum}
                          placeholder="Enter Whatsapp Number"
                        />
                      </div>
                    </div>

                    <div className="col-12 mb-4">
                      <div className="acceptTerms">
                        <input
                          type="checkbox"
                          name="terms"
                          id=""
                          onChange={(e) => onTextChange(e)}
                          className="me-2"
                        />
                        By submitting this form, you agree to our{" "}
                        <Link to="/terms-and-conditions" className="font-bold">
                          terms & conditions
                        </Link>{" "}
                        and confirm your interest in joining the{" "}
                        <b>Annual Group</b>.
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12 text-center">
                      <button
                        onClick={register}
                        type="submit"
                        className="themeBtn w-100"
                      >
                        {loader && (
                          <i
                            className="fa fa-spinner fa-pulse mx-2 mb-1"
                            style={{ position: "relative", top: "-0px" }}
                          ></i>
                        )}
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )} */}
        </div>
      </Modal.Body>
    </Modal>
    // <Modal show={show} onHide={handleClose} centered className="videoM">
    //   <Modal.Body>
    //     <div className="otpModalMain">
    //       <div className="closeIcon" onClick={handleClose}>
    //         <i className="fa-solid fa-xmark" />
    //       </div>

    //       {/* <h2 className="title color-dark mb-0">To Join Yearly Group.</h2> */}
    //       <h5 className="color-dark text-center mb-4 mt-2">
    //         Now you can pay in crypto.
    //       </h5>

    //       <div className="homeVideoM mb-3">
    //         <iframe
    //           className="w-100"
    //           width="560"
    //           height="315"
    //           src="https://www.youtube.com/embed/cZheCh813xE?si=8PfVPJqiepBr6UQY&autoplay=1&mute=1"
    //           title="YouTube video player"
    //           frameborder="0"
    //           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    //           referrerpolicy="strict-origin-when-cross-origin"
    //           allowfullscreen
    //         ></iframe>
    //         {/* <video  className='w-100' controls>
    //                   <source src={homeVideo} type="video/webm" />
    //                   <source src={homeVideo} type="video/mp4" />
    //                   Sorry, your browser doesn't support videos.
    //               </video> */}
    //       </div>

    //       <p className="teather">TETHER- USDT</p>
    //       <p className="teather">NETWORK- TRC20 ADDRESS:</p>
    //       <p className="teather">
    //         TCck5FUoB6p34cwvfhkS93aj9nHcuNeDBz
    //         <span
    //           className="copyToClip"
    //           onClick={() => handleCopy("TCck5FUoB6p34cwvfhkS93aj9nHcuNeDBz")}
    //         >
    //           <i className="fa-regular fa-copy ms-2 cursor-pointer"></i>
    //           <span>{copyText}</span>
    //         </span>
    //       </p>
    //       <p className="teather mb-3">
    //         PAYMENT: <span>1250$</span>
    //       </p>

    //       <div className="noteTitle">Note:</div>
    //       {/* <div className="closed">Membership is closed! </div>
    //           <div className="closed">Visit again </div> */}
    //       {/* <div className="note">
    //             Do not send any amount except yearly amount; If you send so, the amount will not be refunded and this account is just for yearly $1250 worth of usdt and again it is NOT Refundable. Send your CNIC front and back picture for KYC. If you are underage send your parents or school/college id card.
    //           </div> */}
    //       <div className="note">
    //         Do screen recording while doing transaction and Do not send any
    //         amount except yearly amount; If you send so, the amount will not be
    //         refunded and this account is just for yearly $1250 worth of usdt and
    //         again it is NOT Refundable. Send your CNIC front and back picture
    //         for KYC. If you are underage send your parents or school/college id
    //         card.
    //       </div>
    //       {/* <div className="desc1">Once you done payment Please Send the TX id and do screen record while doing transaction then Whatsapp Message on */}
    //       <div className="desc1">
    //         Once you done payment Please Send transaction successful screenshot,
    //         TX id and video of transaction then Whatsapp Message on
    //         <a href="https://wa.me/+971585036929">+971585036929 </a> send all
    //         details on WhatsApp no call will be entertained Office working
    //         hours.
    //       </div>
    //       <div className="desc1">01:00 PM to 09:00 PM</div>
    //       <div className="desc1">Monday to Friday Only</div>
    //     </div>
    //   </Modal.Body>
    // </Modal>
  );
};
