import React, { useEffect, useState } from "react";
import { Pagination, Stack } from "@mui/material";
import moment from "moment";
import api from "../../api";
import { Loader } from "../global/loader";
import { useDispatch, useSelector } from "react-redux";
import userAction from "../../redux/users/action";
import { useAlert } from "react-alert";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export const PayHistoryUser = ({ SubsData, setSubsData }) => {
  const userStore = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setUser } = userAction;
  const alert = useAlert();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [noOfData, setnoOfData] = useState(0);
  const [loader, setLoader] = useState(false);
  const [reason, setReason] = useState("");
  const [isSubscription, setIsSubscription] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getUsers = async () => {
    try {
      let payload = {
        page: page,
      };
      setLoader(true);
      let token = localStorage.getItem("token");
      axios.defaults.headers.common["Authorization"] = token;
      const userData = await api.getUserPayHistory(payload);
      // console.log(userData);
      setData(userData.post);
      setnoOfData(userData.count);
      setLimit(userData.limit);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, [page]);

  const cancelSubcription = async () => {
    try {
      console.log("cancelSubcription");
      if (!reason) {
        alert.show("Please select reason");
        return;
      }
      setLoader(true);
      const payload = {
        _id: userStore?.users?.user?._id,
        id: SubsData?._id,
        subscriptionId: SubsData?.subscriptionId || "",
        reason: reason || "",
      };
      // console.log(payload);

      const resp = await api.cancelPayment(payload);
      // console.log(resp?.res);
      setLoader(false);
      handleClose();
      if (resp?.res?.status == "canceled") {
        setIsSubscription(false);
        setSubsData({
          ...SubsData,
          unSubscribeReason: reason,
        });
        alert.show("Now your subscription has canceled.");
      } else {
        alert.show("There is an issue for cancel, try later.");
      }
    } catch (error) {
      setLoader(false);
      console.log(error.message);
      handleClose();
      var err = error?.message || "";
      if (err.includes("No such subscription")) {
        alert.show("No such subscription");
      } else {
        alert.show(err);
      }
    }
  };
  const formatDate = (date) => {
    let dateTemp = moment(date).format("MMMM Do YYYY, h:mm:ss a");
    return dateTemp;
  };

  useEffect(() => {
    if (SubsData?.isSubscription) {
      setIsSubscription(true);
    }
  }, [SubsData]);
  console.log(SubsData?.unSubscribeReason);

  const onStripePay = async () => {
    try {
      setLoader(true);
      if (!userStore?.users?.user?._id) {
        alert.show("User not found");
      }
      const payload = {
        _id: userStore?.users?.user?._id,
        item: 1,
        months: 1,
      };
      const stripeRes = await api.createPayment(payload);

      if (!stripeRes?.link) {
        throw new Error("Something went wrong");
      }

      window.open(stripeRes?.link, "_self");
      setLoader(false);
    } catch (e) {
      alert.show(e.message);
      setLoader(false);
    }
  };

  return (
    <div className="payHistoryPage">
      <div className="container">
        <h2 className="text-center mt-4">Subscription History</h2>
        <Table
          data={data}
          setPage={setPage}
          page={page}
          noOfData={noOfData}
          limit={limit}
        />
        {isSubscription && !SubsData?.unSubscribeReason ? (
          <div className="mt-2 mb-4 text-center">
            Do you want to cancel your Subscription?{" "}
            <a
              style={{ color: "#384ef4", cursor: "pointer" }}
              onClick={handleShow}
            >
              Click Here
            </a>
          </div>
        ) : SubsData?.unSubscribeReason ? (
          <div className="mt-2 mb-4 text-center">
            You don't have any more ACTIVE subscription. Buy an Active
            subscription{" "}
            <a
              style={{ color: "#384ef4", cursor: "pointer" }}
              onClick={onStripePay}
            >
              Click here to Re-Subscibe
            </a>
          </div>
        ) : (
          <div className="mt-2 mb-4 text-center">
            You don't have any more ACTIVE subscription. Buy an Active
            subscription{" "}
            <a
              style={{ color: "#384ef4", cursor: "pointer" }}
              onClick={() => navigate("/checkout")}
            >
              Click Here
            </a>
          </div>
        )}
      </div>
      {loader && <Loader />}
      <Modal show={show} onHide={handleClose} centered className="videoM">
        <Modal.Body>
          <div className="otpModalMain">
            <div className="closeIcon" onClick={handleClose}>
              <i className="fa-solid fa-xmark" />
            </div>

            <h3 className="my-4 text-center">
              Why are you canceling your subscription?
            </h3>
            <div className="form-group mb-3 position-relative">
              <i className="fa-solid fa-caret-down  arrowDownIco" />

              <select
                name="reason"
                className="form-control"
                onChange={(e) => setReason(e.target.value)}
              >
                <option value="">Select Reason</option>
                <option value="Too Expensive – The subscription cost is too high for me.">
                  Too Expensive – The subscription cost is too high for me.
                </option>
                <option value="Not Enough Value – I’m not getting the expected benefits from the subscription.">
                  Not Enough Value – I’m not getting the expected benefits from
                  the subscription.
                </option>
                <option value="No Longer Interested – I’m no longer interested in web3 education or the content.">
                  No Longer Interested – I’m no longer interested in web3
                  education or the content.
                </option>
                <option value="Found an Alternative – I found a different platform or service that suits my needs better.">
                  Found an Alternative – I found a different platform or service
                  that suits my needs better.
                </option>
                <option value="Financial Issues – I can't afford the subscription right now.">
                  Financial Issues – I can't afford the subscription right now.
                </option>
                <option value="Technical Issues – I’m facing problems with the platform, website, or service.">
                  Technical Issues – I’m facing problems with the platform,
                  website, or service.
                </option>
                <option value="Website is Hard to Use – I find the website difficult to navigate or not user-friendly.">
                  Website is Hard to Use – I find the website difficult to
                  navigate or not user-friendly.
                </option>
              </select>
            </div>
            <h3 className="my-4 text-center">
              Your subscription will ends in{" "}
              <b>{formatDate(SubsData?.nextPayment)}</b>
            </h3>
            {loader ? (
              <button className="themeBtn w-100">
                <i
                  className="fa fa-spinner fa-pulse mx-2 mb-1"
                  style={{ position: "relative", top: "-0px" }}
                ></i>
                loading
              </button>
            ) : (
              <button className="themeBtn w-100" onClick={cancelSubcription}>
                Confirm
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
const Table = ({ data, page, setPage, noOfUser, limit }) => {
  const userStore = useSelector((state) => state.userReducer);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const formatDate = (date) => {
    let dateTemp = moment(date).format("MMMM Do YYYY, h:mm:ss a");
    return dateTemp;
  };

  function getInitials(username) {
    // Split the username into words (assuming space separates the first and last names)
    const nameParts = username.split(" ");

    if (nameParts.length < 2) {
      // If the username doesn't have both a first and last name, return the initials of what is available
      return nameParts[0].charAt(0).toUpperCase(); // Assuming first name is present
    }

    // Extract the first character of the first and last name and return them as uppercase
    const firstInitial = nameParts[0].charAt(0).toUpperCase();
    const lastInitial = nameParts[1].charAt(0).toUpperCase();

    return firstInitial + lastInitial;
  }

  return (
    <>
      <div className="card sortby-box br-10 border-transparent mt-3">
        <div className="card-header br-10  bg-white border-transparent py-3 ">
          <div className="table-responsive">
            <table className="table table-hover drivers-table">
              <thead>
                <tr>
                  <th scope="col" className="">
                    Email
                  </th>
                  <th scope="col" className="">
                    Amount
                  </th>
                  <th scope="col" className="">
                    Type
                  </th>
                  <th scope="col" className="">
                    Paid At
                  </th>
                  {/* <th scope="col" className="">
                    Subscription End
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {data.map((item, key) => (
                  <tr key={key}>
                    <td>
                      <div className="profileM">
                        <div className="profileIco">
                          {getInitials(userStore?.users?.user?.name) || "-"}
                        </div>
                        {userStore?.users?.user?.email}
                      </div>
                    </td>
                    <td scope="row">
                      <div className="table-text-dark w-max-c db">
                        {item?.amountPaid}$
                      </div>
                    </td>
                    <td scope="row">
                      <div className="table-text-dark w-max-c db">
                        {item?.type == "1" ? "Monthly" : "Yearly"}
                      </div>
                    </td>
                    <td>
                      <span className="table-text-dark w-max-c db">
                        {formatDate(item?.createdAt)}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-center">
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(noOfUser / limit)}
                page={page}
                onChange={handleChange}
              />
            </Stack>
          </div>
        </div>
      </div>
    </>
  );
};
