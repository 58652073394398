import React, { useEffect, useState } from "react";
import logo from "../../images/homepage/logoNew.svg";
import homeIco from "../../images/homepage/homeIco.svg";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import "./homeNew.css";
import { useDispatch, useSelector } from "react-redux";
import userAction from "../../redux/users/action";

import { Modal } from "react-bootstrap";
import broadCastIco from "../../images/broadCastIco.png";
import { useMediaQuery } from "react-responsive";
import { BroadcastModal } from "../modals/broadcastModal";
import { AnnualModal } from "../modals/annualModal";
import {
  courseDataMonthly,
  courseDataYearly,
  cryptoMiningData,
  youtubeMember,
} from "./courseData";

export const NavbarNew = ({ show, setShow, SubsData, setSubsData }) => {
  const userStore = useSelector((state) => state.userReducer);
  const { setUser } = userAction;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [courseData, setCourseData] = useState([]);
  const [activeBtn, setActiveBtn] = useState("monthly");
  const [basicType, setBasicType] = useState("guide1");
  const [YearlyType, setYearlyType] = useState("guide1");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showYearly, setShowYearly] = useState(false);
  const handleCloseYearly = () => setShowYearly(false);
  const handleShowYearly = () => setShowYearly(true);

  const [showBroadcast, setShowBroadcast] = useState(false);
  const handleCloseBroadcast = () => setShowBroadcast(false);
  const handleShowBroadcast = () => setShowBroadcast(true);

  const isTab = useMediaQuery({
    query: "(max-width: 1200px)",
  });
  const logout = () => {
    dispatch(setUser({}));
    localStorage.removeItem("token");
    setSubsData({});
    navigate("/");
  };

  // const handleSetBasic = () => {
  //   setCourseData(courseData1)
  //   setActiveBtn("beginner")
  // }
  const handleSetMonthly = () => {
    setCourseData(courseDataMonthly);
    setActiveBtn("monthly");
  };
  // const handleSetBasicGuide = (type) => {
  //   setActiveBtn("monthly");
  //   if (type == "guide1") {
  //     setCourseData(courseDataMonthlyGuide1);
  //     setBasicType("guide1");
  //   }
  //   if (type == "guide2") {
  //     setCourseData(courseDataMonthlyGuide2);
  //     setBasicType("guide2");
  //   }
  //   if (type == "guide3") {
  //     setCourseData(courseDataMonthlyGuide3);
  //     setBasicType("guide3");
  //   }
  //   if (type == "guide4") {
  //     setCourseData(courseDataMonthlyGuide4);
  //     setBasicType("guide4");
  //   }
  //   if (type == "guide5") {
  //     setCourseData(courseDataMonthlyGuide5);
  //     setBasicType("guide5");
  //   }
  // };

  const handleSetYearly = () => {
    setCourseData(courseDataYearly);
    setActiveBtn("yearly");
  };
  // const handleSetYearlyGuide = (type) => {
  //   setActiveBtn("yearly");
  //   if (type == "guide1") {
  //     setCourseData(courseDataYearlyGuide1);
  //     setYearlyType("guide1");
  //   }
  //   if (type == "guide2") {
  //     setCourseData(courseDataYearlyGuide2);
  //     setYearlyType("guide2");
  //   }
  //   if (type == "guide3") {
  //     setCourseData(courseDataYearlyGuide3);
  //     setYearlyType("guide3");
  //   }
  //   if (type == "guide4") {
  //     setCourseData(courseDataYearlyGuide4);
  //     setYearlyType("guide4");
  //   }
  // };

  const handleMining = () => {
    setActiveBtn("mining");
    setCourseData(cryptoMiningData);
  };
  const handleMemeber = () => {
    setActiveBtn("member");
    setCourseData(youtubeMember);
  };
  // const handleSetAdvance = (type) => {
  //   setActiveBtn("advance");
  //   if (type == "scd") {
  //     setCourseData(courseDataAdvanceSCD);
  //   } else {
  //     setCourseData(courseDataAdvanceCBD);
  //   }
  // };

  useEffect(() => {
    setCourseData(courseDataMonthly);
  }, []);
  const [expanded, setExpanded] = useState(true);
  useEffect(() => {
    if (isTab) {
      if (!expanded) {
        let toggler = document.getElementById("navbar-toggler");
        toggler.click();
      }
    }
  }, [expanded]);

  return (
    <nav className="navbar navbar-expand-xl navbar-light navbarNew">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="waqarzaka" />
        </Link>
        <button
          className="navbar-toggler"
          id="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setExpanded(true)}
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="homeNav" aria-current="page" to="/">
                <img src={homeIco} alt="home" />
              </Link>
            </li>
            <li className="nav-item">
              <a className="nav-link" onClick={handleShowBroadcast}>
                Channels
              </a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link" onClick={handleShowYearly}>
                Join Yearly
              </a>
            </li> */}
            <li className="nav-item">
              <a
                className="nav-link"
                href="/#services"
                onClick={() => setExpanded(false)}
              >
                Services
              </a>
            </li>
            {userStore?.users?.user?._id && (
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  onClick={() => setExpanded(false)}
                  to="/portfolio"
                >
                  {" "}
                  Portfolio{" "}
                </NavLink>
              </li>
            )}
            {/* {!SubsData?.isSubscription && (
              <li className="nav-item">
                <a className="nav-link" onClick={handleShow}>
                  Courses
                </a>
              </li>
            )} */}
            <li className="nav-item">
              <a
                className="nav-link"
                href="/#about"
                onClick={() => setExpanded(false)}
              >
                About
              </a>
            </li>
            {userStore?.users?.user?._id && (
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  onClick={() => setExpanded(false)}
                  to="/checkout"
                >
                  {" "}
                  Subscription{" "}
                </NavLink>
              </li>
            )}
            {/* <li className="nav-item">
              <a
                className="nav-link"
                onClick={() => setExpanded(false)}
                href="/#testimonial"
              >
                {" "}
                Testimonial{" "}
              </a>
            </li> */}
            {userStore?.users?.user?._id && (
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  onClick={() => setExpanded(false)}
                  to="/dashboard"
                >
                  {" "}
                  Dashboard{" "}
                </NavLink>
              </li>
            )}
          </ul>
          <div className="regMain">
            {userStore?.users?.user?.name ? (
              <div className="logoutDrop">
                <button className="SignUpBtn me-0">
                  {userStore?.users?.user?.name.length > 12
                    ? userStore?.users?.user?.name?.slice(0, 12) + " ..."
                    : userStore?.users?.user?.name}
                  <i
                    className="fa-solid fa-chevron-down ms-2"
                    style={{ position: "relative", right: "-10px" }}
                  ></i>
                  <div className="logout">
                    <Link className="link" to={"/subscription/history"}>
                      Pay History
                    </Link>
                    {/* <Link className="link" to={"/contact-support"}>
                      Contact Support
                    </Link> */}
                    <a className="link" onClick={logout}>
                      Logout
                    </a>
                  </div>
                </button>
              </div>
            ) : (
              <>
                <Link className="loginBtn" to="/login">
                  Login
                </Link>
                <Link className="SignUpBtn" to="/signup">
                  Sign Up
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered className="courseModal">
        <Modal.Body>
          <div className="otpModalMain">
            <div className="closeIcon" onClick={handleClose}>
              <i className="fa-solid fa-xmark" />
            </div>
            <div className="couseModal">
              <div className="mainBtn">
                {/* <div className={activeBtn == "beginner" ?'groupBtn ':'groupBtn active'} onClick={handleSetBasic}>For Beginner's</div> */}
                <div
                  className={
                    activeBtn == "monthly" ? "groupBtn " : "groupBtn active"
                  }
                  onClick={handleSetMonthly}
                >
                  Facebook Monthly
                </div>
                <div
                  className={
                    activeBtn == "yearly" ? "groupBtn " : "groupBtn active"
                  }
                  onClick={handleSetYearly}
                >
                  For Yearly
                </div>
                {/* <div className={activeBtn == "advance" ?'groupBtn':'groupBtn active'}  onClick={handleSetAdvance}>For Advanced</div> */}
                <div
                  className={
                    activeBtn == "mining" ? "groupBtn " : "groupBtn active "
                  }
                  onClick={handleMining}
                >
                  Crypto Mining
                </div>
                <div
                  className={
                    activeBtn == "member" ? "groupBtn" : "groupBtn active"
                  }
                  onClick={handleMemeber}
                >
                  Youtube Members Only
                </div>
              </div>
              {/* {activeBtn == "monthly" && 
              <div className="advanceTypeMain mainBtn mt-2">
                <div className={basicType == "guide1"? 'groupBtn':'groupBtn active'}  onClick={()=>handleSetBasicGuide("guide1")}>Guide 1 Basics</div>
                <div className={basicType == "guide2"? 'groupBtn':'groupBtn active'}  onClick={()=>handleSetBasicGuide("guide2")}>Guide 2 Basics</div>
                <div className={basicType == "guide3"? 'groupBtn':'groupBtn active'}  onClick={()=>handleSetBasicGuide("guide3")}>Guide 3 Trading</div>
                <div className={basicType == "guide4"? 'groupBtn':'groupBtn active'}  onClick={()=>handleSetBasicGuide("guide4")}>Guide 4- Basics of Blockchain</div>
                <div className={basicType == "guide5"? 'groupBtn':'groupBtn active'}  onClick={()=>handleSetBasicGuide("guide5")}>Guide 5- Advance Blockchain</div>
              </div>
            } */}
              {/* {activeBtn == "yearly" && 
              <div className="advanceTypeMain mainBtn mt-2">
                <div className={YearlyType == "guide1"? 'groupBtn':'groupBtn active'}  onClick={()=>handleSetYearlyGuide("guide1")}>Guide 1 Basics</div>
                <div className={YearlyType == "guide2"? 'groupBtn':'groupBtn active'}  onClick={()=>handleSetYearlyGuide("guide2")}>Guide 2 Basics</div>
                <div className={YearlyType == "guide3"? 'groupBtn':'groupBtn active'}  onClick={()=>handleSetYearlyGuide("guide3")}>Guide 3 Trading</div>
                <div className={YearlyType == "guide4"? 'groupBtn':'groupBtn active'}  onClick={()=>handleSetYearlyGuide("guide4")}>Guide 4- Basics of Blockchain</div>
              </div>
            } */}
              {/* {activeBtn == "advance" && 
              <div className="advanceTypeMain mainBtn mt-2">
                <div className={courseData[0]?.title == "Lecture 1"? 'groupBtn active':'groupBtn '}  onClick={()=>handleSetAdvance("cbd")}>CUSTOM BLOCKCHAIN DEVELOPMENT</div>
                <div className={courseData[0]?.title == "Lecture 1"? 'groupBtn':'groupBtn active'}  onClick={()=>handleSetAdvance("scd")}>SMART CONTRACT DEVELOPMENT</div>
              </div>
            } */}
              <div className="linkM">
                {/* <a href={val?.link} target='_blank' className="link" key={key}>{val?.title}</a> */}
                {courseData.map((val, key) => (
                  <div className="accordion" id="accordionExample">
                    <div className="">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="link"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapseOne${key}`}
                          aria-expanded="true"
                          aria-controls={`collapseOne${key}`}
                        >
                          {val?.title}
                        </button>
                      </h2>
                      {val?.link.split(",").length > 1 ? (
                        <div
                          id={`collapseOne${key}`}
                          className="accordion-collapse collapse"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            {val?.link.split(",").map((item, key1) => (
                              <a
                                className="mb-1"
                                href={item}
                                key1={key1}
                                target="_blank"
                              >
                                {item}
                              </a>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <div
                          id={`collapseOne${key}`}
                          className="accordion-collapse collapse"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <a href={val?.link} target="_blank">
                              {val?.link}
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              {/* <div className="videoMain">
              {courseData.map((val,key)=> (
              <div className="video" key={key}>
                {val?.video}
              </div>
              ))}
            </div> */}
              {/* {courseData[0]?.video &&
            <div className='text-center'>
              <a className='default-btn' href="https://www.youtube.com/playlist?list=PLeYUkJPHvOtuiaCtvKTA5cKPqUPwYphLC" target="_blank" rel="noopener noreferrer">Playlist</a>
            </div>
            } */}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <AnnualModal show={showYearly} handleClose={handleCloseYearly} />

      <BroadcastModal
        showBroadcast={showBroadcast}
        handleCloseBroadcast={handleCloseBroadcast}
      />
    </nav>
  );
};
