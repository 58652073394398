import React, { useState } from "react";
import logo from "../../images/homepage/logoNew.svg";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

export const FooterNew = ({ show, setShow }) => {
  const [showMore, setShowMore] = useState(false);
  const handleCloseMore = () => setShowMore(false);
  const handleShowMore = () => setShowMore(true);
  return (
    <footer className="footer-new">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-12 mb-4">
            <div className="">
              <div className="footer-logo">
                <a href="#">
                  <img src={logo} alt="Images" />
                </a>
              </div>
              <p className="desc">
                {" "}
                DISCLAIMER: We are an Educational Platform/Teachers, We dont ask
                for investments and we are not financial advisors. The Following
                groups are the only offical groups of Waqar Zaka.{" "}
              </p>
              <div className="social-link">
                <a href="https://www.facebook.com/waqarzaka/" target="_blank">
                  <i className="fa-brands fa-facebook-f" />
                </a>

                <a href="https://twitter.com/zakawaqar" target="_blank">
                  <i className="fa-brands fa-twitter" />
                </a>

                <a
                  href="https://www.instagram.com/waqarzaka/?hl=en"
                  target="_blank"
                >
                  <i className="fa-brands fa-instagram" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-6 mb-4">
            <div className=" ps-5">
              <h3>Resources</h3>
              <ul className="footer-list">
                <li>
                  <a href="#"> Home </a>
                </li>
                {/* <li>
                  <a onClick={() => setShow(true)}> Courses </a>
                </li> */}
                <li>
                  <a href="#about"> About </a>
                </li>
                {/* <li>
                  <a href="#testimonial"> Testimonial </a>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-6 mb-4">
            <div className=" ps-5">
              <h3>Official Info</h3>
              <ul className="footer-contact">
                {/* <li><i className="fa-solid fa-location-dot" />
                <div className="content">
                  <h4>Location:</h4>
                  <span>DSO_IFZA IFZA Properties Dubai Silicon Oasis</span>
                </div>
              </li> */}
                <li>
                  <div className="content d-flex flex-wrap">
                    <h4 className="me-1 mb-0">Email:</h4>
                    <a href="mailto:info@waqarzaka.net">info@waqarzaka.net</a>
                  </div>
                </li>
                <li>
                  <div className="content">
                    <Link to="/terms-and-conditions">Terms & conditions</Link>
                  </div>
                </li>
                <li>
                  <div className="content">
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </div>
                </li>
                {/* <li>
                  <div className="content">
                    <a>Powered by Capital Media LLC</a>
                  </div>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="copy-right-text text-center">
              <p>
                {" "}
                Copyright © 2025 <b>Waqar Zaka</b> All Rights Reserved ,
                <span style={{ fontSize: 13 }}> Capital Media LLC</span>
              </p>
            </div>
          </div>
        </div>

        <div className="termsFooter">
          <h6 style={{ fontSize: 14 }}>
            LEGAL DISCLAIMER & TERMS OF USE/CONDITIONS
          </h6>
          <p className="mb-3" style={{ fontSize: 13 }}>
            <b>Last Updated:</b> 20.03.2025{" "}
          </p>
          <p className="mb-3" style={{ fontSize: 13 }}>
            Welcome to www.waqarzaka.net (the "Website"). By accessing or using
            this Website, you agree to the following disclaimers, terms, and
            policies. If you do not agree with any part of this disclosure,
            please discontinue use immediately.{" "}
            <span className=" more cursor-pointer" onClick={handleShowMore}>
              See More
            </span>
          </p>
        </div>
      </div>
      <Modal size="lg" show={showMore} onHide={handleCloseMore} centered>
        <Modal.Body>
          <div className="otpModalMain broadcastModal">
            <div className="closeIcon" onClick={handleCloseMore}>
              <i className="fa-solid fa-xmark" />
            </div>

            <div className="showMoreModalM">
              <div className="showMoreModal py-4">
                <h3>LEGAL DISCLAIMER & TERMS OF USE/CONDITIONS</h3>
                <p className="mb-3">
                  <b>Last Updated:</b> 20.03.2025{" "}
                </p>
                <p className="mb-3">
                  Welcome to www.waqarzaka.net (the "Website"). By accessing or
                  using this Website, you agree to the following disclaimers,
                  terms, and policies. If you do not agree with any part of this
                  disclosure, please discontinue use immediately.{" "}
                </p>

                <h4>GENERAL DISCLAIMER</h4>
                <p className="mb-3">
                  This Website is for educational and informational purposes
                  only. We do not provide financial, investment, legal, or tax
                  advice. Any content, materials, or courses available on this
                  platform should not be construed as professional financial
                  guidance. Before making any trading or investment decisions,
                  consult with a licensed financial advisor, attorney, or tax
                  professional to assess your individual circumstances.
                </p>
                <h4>NO FINANCIAL OR INVESTMENT ADVICE</h4>
                <p className="mb-3">
                  We are not financial advisors, brokers, or investment
                  professionals. No content on this Website constitutes
                  financial, investment, or legal advice. Any financial or
                  trading decisions you make based on our content are at your
                  own risk. Past performance of financial markets,
                  cryptocurrencies, or trading strategies is not indicative of
                  future results.
                </p>
                <h4>Risk Disclosure</h4>

                <p className="mb-3">
                  Trading and investing involve substantial risk, including
                  potential loss of your entire investment. The cryptocurrency
                  and forex markets are highly volatile and not suitable for all
                  investors. You should only trade or invest money that you can
                  afford to lose.
                </p>
                <h4>Personal Responsibility</h4>
                <p className="mb-3">
                  By using this Website, you acknowledge that you are solely
                  responsible for your financial decisions. We are not liable
                  for any financial losses, damages, or negative outcomes from
                  using our educational materials
                </p>
                <p className="mb-3">
                  We do not guarantee any specific financial outcomes, earnings,
                  or success from using our content. Any testimonials, success
                  stories, or examples are exceptional cases and not typical
                  results. Your personal financial success depends on various
                  factors outside our control, including market conditions,
                  experience, and risk tolerance.
                </p>
                <p className="mb-3">
                  All information is provided "as is" and "as available", with
                  no warranties of accuracy, completeness, or reliability. We
                  strive to provide up-to-date information, but we do not
                  guarantee that all content is free from errors, omissions, or
                  outdated material. By accessing this Website, you agree that
                  we, our owners, employees, and affiliates are not responsible
                  for any financial losses resulting from your reliance on our
                  content.
                </p>
                <p className="mb-3">
                  This Website may include links to third-party websites,
                  sponsored content, or affiliate promotions.
                </p>
                <p className="mb-3">
                  We do not own, control, or guarantee the accuracy,
                  reliability, or legitimacy of third-party services. If you
                  engage with third-party services through our links, you do so
                  at your own risk, and we are not responsible for any issues or
                  disputes arising from those engagements. We may earn a
                  commission from affiliate links, but this does not influence
                  our content recommendations.
                </p>
                <p className="mb-3">
                  By using this Website, you acknowledge that you have read and
                  understood our Privacy Policy, which outlines:
                </p>
                <p className="mt-0">
                  What personal data we collect (e.g., email, payment details,
                  analytics).
                </p>
                <p className="mt-0">How we use and store data.</p>
                <p className="mt-0">Third-party sharing policies</p>
                <p className="mt-0">
                  User rights, including data deletion requests.
                </p>
                <p className="mt-0">
                  For more details, refer to our Privacy Policy page.
                </p>

                <p className="mb-3">
                  All sales of digital products, including courses, are final
                  and non-refundable, except in cases of accidental duplicate
                  purchases
                </p>
                <p className="mb-3">
                  Users may not copy, distribute, or reproduce any content
                  without written permission. Unauthorized use of our content
                  may result in legal action
                </p>
                <p className="mb-3">
                  Users must not engage in fraud, harassment, illegal
                  activities, or violation of these terms. We reserve the right
                  to suspend or terminate any user account that violates these
                  terms
                </p>
                <p className="">
                  To the fullest extent permitted by law, we shall not be liable
                  for any direct, indirect, incidental, consequential, or
                  special damages arising from:
                </p>
                <p>Use of or inability to use our Website.</p>
                <p>Errors or inaccuracies in content.</p>
                <p>Third-party services or external links.</p>
                <p>Loss of profits, revenue, or financial losses.</p>
                <p className="mt-3">
                  We reserve the right to modify or update this legal disclosure
                  at any time without prior notice. It is your responsibility to
                  review this page periodically for any changes.
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </footer>
  );
};
