import React from "react";
import { Modal } from "react-bootstrap";
import facebookIco from "../../images/homepage/facebookIco.svg";
import telegramIco from "../../images/homepage/telegramIco.png";
import discordIco from "../../images/homepage/discordIco.png";
import whatsappICo from "../../images/homepage/whatsappICo.png";
import youtubeIco from "../../images/homepage/youtubeIco.svg";
import twitterICo from "../../images/homepage/twitterICo.png";
import instaICo from "../../images/homepage/instaICo.png";

export const SocialModal = ({
  show,
  handleClose,
  handleShowMonthly,
  handleShowYearly,
}) => {
  return (
    <Modal size="lg" show={show} onHide={handleClose} centered>
      <Modal.Body>
        <div className="otpModalMain broadcastModal">
          <div className="closeIcon" onClick={handleClose}>
            <i className="fa-solid fa-xmark" />
          </div>

          <div className="socialModal">
            <h2 className="text-center mb-5">Social Media Channels</h2>
            <div className="row jc-center">
              {/* <div className="col-6">
                <a className="monthly socialL" onClick={handleShowMonthly}>
                  <img src={facebookIco} alt="" />
                  Monthly Group
                </a>
              </div> */}
              <div className="col-12">
                <a
                  href="https://t.me/waqarzaka"
                  target="_blank"
                  className="tele socialL"
                >
                  <img src={telegramIco} alt="" />
                  Join Telegram
                </a>
              </div>
              <div className="col-12">
                <a
                  href="https://discord.gg/waqarzaka"
                  target="_blank"
                  className="discord socialL"
                >
                  <img src={discordIco} alt="" />
                  Join Discord
                </a>
              </div>
              {/* <div className="col-6">
                <a className="annual socialL" onClick={handleShowYearly}>
                  <img src={facebookIco} alt="" />
                  Annual Group
                </a>
              </div> */}
              {/* <div className="col-6">
                <a href="" target="_blank" className="whatsapp socialL">
                  <img src={whatsappICo} alt="" />
                  Whatsapp Group
                </a>
              </div> */}
              <div className="col-12">
                <a
                  href="https://www.youtube.com/c/waqarzaka"
                  target="_blank"
                  className="youtube socialL"
                >
                  <img src={youtubeIco} alt="" />
                  Youtube channel
                </a>
              </div>
              <div className="col-12">
                <a
                  href="https://www.tiktok.com/@waqarzaka"
                  target="_blank"
                  className="tiktok socialL"
                >
                  <i className="fa-brands fa-tiktok"></i>
                  Tiktok
                </a>
              </div>
            </div>

            <div className="socialRow">
              <a
                href="https://m.me/j/Abat_s17duf8Dm-5/"
                target="_blank"
                className="ico"
              >
                <img src={facebookIco} alt="facebook" />
              </a>
              <a
                href="https://twitter.com/zakawaqar"
                target="_blank"
                className="ico"
              >
                <img src={twitterICo} alt="twitter" />
              </a>
              <a
                href="https://ig.me/j/AbbEEsvUoFTOHaPf"
                target="_blank"
                className="ico"
              >
                <img src={instaICo} alt="insta" />
              </a>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
