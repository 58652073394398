import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const AuthNotLoginRoutes = (props) => {
  const userStore = useSelector((state) => state.userReducer);
  return userStore?.users?.token ? <Navigate to="/checkout" /> : <Outlet />;
};
const AuthOnlyUserRoutes = (props) => {
  const userStore = useSelector((state) => state.userReducer);
  return userStore?.users?.token ? <Outlet /> : <Navigate to="/" />;
};

export { AuthNotLoginRoutes, AuthOnlyUserRoutes };
