import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";

import edit from "../../images/edit.svg";
import Delete from "../../images/delete.svg";
import Logo from "../../images/logo-2.png";
import { useSelector } from "react-redux";
import api from "../../api";
import { Pagination, Stack } from "@mui/material";
import { Loader } from "./loader";
import moment from "moment";
import copy from "copy-to-clipboard";
import { Modal } from "react-bootstrap";

function PaidUsers({ adminType }) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [noOfUser, setnoOfUser] = useState(0);
  const [loader, setLoader] = useState(true);
  const [searchMail, setSearchMail] = useState("");
  const [resetPage, setResetPage] = useState(false);
  const [reason, setReason] = useState("");

  const [selectedSubscription, setSelectedSubscription] = useState({});
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    console.log(data);
    setSelectedSubscription(data);
    setShow(true);
  };

  // console.log(window.location);
  // console.log(match.url);
  const getUsers = async () => {
    try {
      let payload = {
        page: page,
      };
      setLoader(true);
      console.log(localStorage.getItem("token"));

      axios.defaults.headers.common["Authorization"] =
        localStorage.getItem("token");
      const userData = await api.getPaidUsers(payload);
      // console.log(userData);
      setData(userData.user);
      setnoOfUser(userData.count);
      setLimit(userData.limit);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  const deleteSubscription = async (id) => {
    try {
      let payload = {
        id: id,
      };
      setLoader(true);
      const userData = await api.SuperAdminDeleteSubs(payload);
      console.log(userData);
      if (userData?.success) {
        console.log("deleted");
        alert("Subscription deleted");
        getUsers();
      }
      setLoader(false);
    } catch (error) {
      console.log(error);
      alert("Something went wrong");
      setLoader(false);
    }
  };

  const cancelSubcription = async () => {
    try {
      console.log("cancelSubcription");
      if (!reason) {
        alert("Please select reason");
        return;
      }
      setLoader(true);

      const payload = {
        id: selectedSubscription?._id,
        subscriptionId: selectedSubscription?.subscriptionId || "",
        reason: reason || "",
      };
      // console.log(payload);

      const resp = await api.SuperAdminCancelSubs(payload);
      // console.log(resp?.res);
      setLoader(false);
      handleClose();
      if (resp?.res?.status == "canceled") {
        getUsers();
        alert("Now user subscription has canceled.");
      } else {
        alert("There is an issue for cancel, try later.");
      }
    } catch (error) {
      setLoader(false);
      console.log(error.message);
      handleClose();
      var err = error?.message || "";
      if (err.includes("No such subscription")) {
        alert("No such subscription");
      } else {
        alert(err);
      }
    }
  };

  const handleSearch = async () => {
    if (searchMail) {
      let payload = {
        email: searchMail,
      };
      const res = await api.searchPaidUsers(payload);
      console.log(res);
      if (res?.success) {
        setData(res?.user);
      } else {
        alert("User not found");
      }
    } else {
      alert("Please insert email for search");
    }
  };

  const formatDate = (date) => {
    let dateTemp = moment(date).format("MMMM Do YYYY, h:mm:ss a");
    return dateTemp;
  };

  useEffect(() => {
    getUsers();
  }, [page, resetPage]);

  const handleReset = () => {
    setResetPage(!resetPage);
    setPage(1);
  };

  return (
    <>
      {/* Begin Page Content */}
      <div className="container-fluid">
        {/* Content Row */}
        <div className="row">
          {/* Area Chart */}
          <div className="col">
            <div className="row mx-0 drivers-row">
              <div className="col-12   d-flex justify-content-between pr-0 flex-wrap">
                <h4 className="color-dark fs-17 dib font-weight-bold mr-5">
                  Paid Users ({noOfUser})
                </h4>
                {/* <a href="#" className="driver-row-link">All</a>
                                 <a href="#" className="driver-row-link">Arbitrators (86)</a>
                                 <a href="#" className="driver-row-link">Mediators (32)</a> */}
                <div className="searchMadmin">
                  <div className="title">Filter: </div>
                  <div className="inputM">
                    <input
                      onChange={(e) => setSearchMail(e.target.value)}
                      type="text"
                      placeholder="Search by email..."
                    />
                    <i
                      className="fa-solid fa-magnifying-glass"
                      onClick={handleSearch}
                    ></i>
                  </div>
                  <button className="reset" onClick={() => handleReset()}>
                    <i className="fa-solid fa-arrow-rotate-right"></i>
                    Reset
                  </button>
                </div>
              </div>
            </div>
            {/* table */}
            <Table
              data={data}
              setPage={setPage}
              page={page}
              noOfUser={noOfUser}
              limit={limit}
              deleteSubscription={deleteSubscription}
              adminType={adminType}
              handleShow={handleShow}
            />
          </div>
        </div>
      </div>
      {/* /.container-fluid */}
      {loader && <Loader />}
      <Modal show={show} onHide={handleClose} centered className="videoM">
        <Modal.Body>
          <div className="otpModalMain">
            <div className="closeIcon" onClick={handleClose}>
              <i className="fa-solid fa-xmark" />
            </div>

            <h3 className="my-4 text-center">
              Why are you canceling user subscription?
            </h3>
            <div className="form-group mb-3 position-relative">
              <i className="fa-solid fa-caret-down  arrowDownIco" />

              <select
                name="reason"
                className="form-control"
                onChange={(e) => setReason(e.target.value)}
              >
                <option value="">Select Reason</option>
                <option value="admin">By Admin</option>
              </select>
            </div>
            <h3 className="my-4 text-center">
              Your subscription will ends in{" "}
              <b>{formatDate(selectedSubscription?.nextPayment)}</b>
            </h3>
            {loader ? (
              <button className="themeBtn w-100">
                <i
                  className="fa fa-spinner fa-pulse mx-2 mb-1"
                  style={{ position: "relative", top: "-0px" }}
                ></i>
                loading
              </button>
            ) : (
              <button className="themeBtn w-100" onClick={cancelSubcription}>
                Confirm
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
const Table = ({
  data,
  page,
  setPage,
  noOfUser,
  limit,
  deleteSubscription,
  adminType,
  handleShow,
}) => {
  // const authedUser = useSelector(s => s.authedUser.authedUser);
  const [copyText, setCopyText] = useState("Copy to clipboard");

  const handleChange = (event, value) => {
    setPage(value);
  };

  const formatDate = (date) => {
    let dateTemp = moment(date).format("MMMM Do YYYY, h:mm:ss a");
    return dateTemp;
  };

  const handleCopy = (text) => {
    copy(text);
    setCopyText("Copied");
    setTimeout(() => {
      setCopyText("Copy to clipboard");
    }, 3000);
  };

  return (
    <>
      <div className="card sortby-box br-10 border-transparent mt-3">
        <div className="card-header br-10  bg-white border-transparent py-3 ">
          <div className="table-responsive">
            <table className="table table-hover drivers-table">
              <thead>
                <tr>
                  <th scope="col" className="">
                    Name
                  </th>
                  <th scope="col" className="">
                    Email
                  </th>
                  <th scope="col" className="">
                    Type
                  </th>
                  <th scope="col" className="">
                    <div className="w-max-c">Last Paid</div>
                  </th>
                  <th scope="col" className="">
                    <div className="w-max-c">Subscription End</div>
                  </th>
                  {adminType == "superadmin" && (
                    <th scope="col" className="">
                      <div className="w-max-c">Actions</div>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {data.map((item, key) => (
                  <tr key={key}>
                    <td scope="row">
                      <div className="table-text-dark w-max-c db">
                        {item?.userdata?.name || item?.name}
                      </div>
                    </td>
                    <td>
                      <span className="table-text-dark w-max-c db">
                        {item?.userdata?.email || item?.email}
                      </span>
                    </td>
                    <td>
                      <span className="table-text-dark w-max-c db">
                        {/* {item?.amount == "10" ? "Monthly" : "Yearly"} */}
                        Monthly
                      </span>
                    </td>
                    <td>
                      <div className="table-text-dark w-max-c db">
                        {item?.lastPaid ? formatDate(item?.lastPaid) : "-"}
                      </div>
                    </td>
                    <td>
                      <div className="table-text-dark w-max-c db">
                        {item?.lastPaid ? formatDate(item?.nextPayment) : "-"}
                      </div>
                    </td>
                    {adminType == "superadmin" && (
                      <td>
                        {item?.subscriptionId == "manual" ? (
                          <button className="makeSubBtn mb-2">
                            Added by Admin
                          </button>
                        ) : (
                          <button
                            className="makeSubBtn mb-2"
                            onClick={() => handleShow(item)}
                          >
                            Cancel Subscription from Stripe
                          </button>
                        )}
                        <button
                          className="makeSubBtn "
                          onClick={() => deleteSubscription(item?._id)}
                        >
                          Delete Subscription from DB
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-center">
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(noOfUser / limit)}
                page={page}
                onChange={handleChange}
              />
            </Stack>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaidUsers;
