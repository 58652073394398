import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";

import edit from "../../images/edit.svg";
import Delete from "../../images/delete.svg";
import Logo from "../../images/logo-2.png";
import { useSelector } from "react-redux";
import api from "../../api";
import { Pagination, Stack } from "@mui/material";
import { Loader } from "./loader";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { EditUserData } from "./adminComp/userEditModal";

function AdminYearlyContact({ adminType }) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [noOfForms, setnoOfForms] = useState(0);
  const [loader, setLoader] = useState(true);
  const [searchMail, setSearchMail] = useState("");
  const [resetPage, setResetPage] = useState(false);

  const [selectedData, setSelectedData] = useState({});

  // console.log(window.location);
  // console.log(match.url);
  const getFormData = async () => {
    try {
      let payload = {
        page: page,
      };
      setLoader(true);
      const userData = await api.getYearlyContactData(payload);
      // console.log(userData);
      setData(userData.contact);
      setnoOfForms(userData.count);
      setLimit(userData.limit);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  // const deleteUser = async (id) => {
  //   try {
  //     let payload = {
  //       id: id,
  //     };
  //     setLoader(true);
  //     const userData = await api.deleteUser(payload);
  //     console.log("deleted");
  //     // console.log(userData);
  //     getUsers();
  //     setLoader(false);
  //   } catch (error) {
  //     console.log(error);
  //     setLoader(false);
  //   }
  // };

  useEffect(() => {
    getFormData();
  }, [page, resetPage]);

  return (
    <>
      {/* Begin Page Content */}
      <div className="container-fluid">
        {/* Content Row */}
        <div className="row">
          {/* Area Chart */}
          <div className="col">
            <div className="row mx-0 drivers-row">
              <div className="col-12   d-flex justify-content-between pr-0 flex-wrap">
                <h4 className="color-dark fs-17 dib font-weight-bold mr-5">
                  Yearly Contact Form ({noOfForms})
                </h4>
              </div>
            </div>
            {/* table */}
            <Table
              data={data}
              setPage={setPage}
              page={page}
              noOfForms={noOfForms}
              limit={limit}
              adminType={adminType}
            />
          </div>
        </div>
      </div>
      {/* /.container-fluid */}
      {loader && <Loader />}
    </>
  );
}
const Table = ({ data, page, setPage, noOfForms, limit }) => {
  // const authedUser = useSelector(s => s.authedUser.authedUser);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const formatDate = (date) => {
    let dateTemp = moment(date).format("MMMM Do YYYY, h:mm:ss a");
    return dateTemp;
  };

  return (
    <>
      <div className="card sortby-box br-10 border-transparent mt-3">
        <div className="card-header br-10  bg-white border-transparent py-3 ">
          <div className="table-responsive">
            <table className="table table-hover drivers-table">
              <thead>
                <tr>
                  <th scope="col" className="">
                    Name
                  </th>
                  <th scope="col" className="">
                    Email
                  </th>
                  <th scope="col" className="">
                    DOB
                  </th>
                  <th scope="col" className="">
                    country
                  </th>
                  <th scope="col" className="">
                    Phone
                  </th>
                  <th scope="col" className="">
                    Time
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, key) => (
                  <tr key={key}>
                    <td scope="row" className="d-flex">
                      <div className="table-text-dark w-max-c db">
                        {item?.name}
                      </div>
                    </td>
                    <td>
                      <span className="table-text-dark w-max-c db">
                        {item?.email}
                      </span>
                    </td>
                    <td>
                      <span className="table-text-dark w-max-c db">
                        {item?.dob}
                      </span>
                    </td>
                    <td>
                      <span className="table-text-dark w-max-c db">
                        {item?.country ? item?.country : "-"}
                      </span>
                    </td>
                    <td>
                      <span className="table-text-dark w-max-c db">
                        {item?.phone ? item?.phone : "-"}
                      </span>
                    </td>
                    <td>
                      <div className="table-text-dark w-max-c db">
                        {formatDate(item?.createdAt)}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-center">
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(noOfForms / limit)}
                page={page}
                onChange={handleChange}
              />
            </Stack>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminYearlyContact;
