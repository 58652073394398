import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import ReactQuill from "react-quill";
import api from "../../../api";
import Logo from "../../../images/logo-2.png";
import add from "../../../images/add.png";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";

export function EditUserData({ getUsers, setShow, selectedData }) {
  const adminStore = useSelector((state) => state.adminReducer);
  const [loader, setLoader] = useState(false);
  const [inputData, setInputData] = useState({
    video: "",
    category: "",
  });
  const [isRecording, setIsRecording] = useState(false);

  const handleSubmit = async () => {
    try {
      if (!inputData?.name) {
        setLoader(false);
        alert("Please select password");
        return;
      }
      if (!inputData?.email) {
        setLoader(false);
        alert("Please insert email");
        return;
      }
      if (!inputData?.password) {
        setLoader(false);
        alert("Please select password");
        return;
      }
      if (!inputData?.gender) {
        setLoader(false);
        alert("Please select password");
        return;
      }
      setLoader(true);
      const formData = {
        _id: selectedData?._id,
        ...inputData,
      };

      const signalResponse = await api.superAdminUserEdit(formData);
      console.log(signalResponse);

      if (signalResponse?.success) {
        alert("User edit successfully");
        setLoader(false);
        setShow(false);
        getUsers();
      } else {
        setLoader(false);
        alert("Something went wrong");
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      alert("Something went wrong");
    }
  };

  const handleChange = (e) => {
    // console.log(e);
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setInputData({ ...selectedData });
  }, []);
  console.log(inputData);
  console.log(selectedData);

  return (
    <>
      <div className="row mx-0 drivers-row">
        <div className="col-xl-6 col-md-7  d-flex justify-content-between pr-0">
          <h4 className="color-dark fs-17 dib font-weight-bold mr-5">
            Edit User
          </h4>
        </div>
      </div>
      <div className="card sortby-box br-10 border-transparent mt-3">
        <div className="card-header br-10  bg-white border-transparent py-3 ">
          <div className="descMainPost">
            <div className="form-group mb-3">
              <input
                type="text"
                name="name"
                className="form-control"
                onChange={handleChange}
                value={inputData?.name}
                placeholder="Add Name"
              />
            </div>
            <div className="form-group mb-3">
              <input
                type="text"
                name="email"
                className="form-control"
                onChange={handleChange}
                value={inputData?.email}
                placeholder="Add Email"
              />
            </div>
            <div className="form-group position-relative mb-3">
              <i className="fa-solid fa-caret-down  arrowDownIco" />
              <select
                onChange={handleChange}
                name="gender"
                id=""
                value={inputData?.gender}
                className="form-control ng-dirty "
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
            <div className="form-group mb-3">
              <label>OTP</label>

              <input
                type="text"
                name="otp"
                className="form-control"
                onChange={handleChange}
                value={inputData?.otp}
                placeholder="Otp"
              />
            </div>
            <label>Email Verify</label>
            <div className="form-group mb-3  position-relative">
              <i className="fa-solid fa-caret-down  arrowDownIco" />

              <select
                name="emailVerified"
                onChange={handleChange}
                value={inputData?.emailVerified}
                className="form-control"
              >
                <option value="">Select Email Verified</option>
                <option value={true}>true</option>
                <option value={false}>false</option>
              </select>
            </div>
            <div className="form-group mb-3">
              <label>Password</label>
              <input
                type="text"
                name="password"
                className="form-control"
                onChange={handleChange}
                value={inputData?.password}
                placeholder="Otp"
              />
            </div>
          </div>

          <div className="sendMailBtn mt-3">
            <button
              onClick={handleSubmit}
              className="btn-dark"
              disabled={isRecording}
            >
              Submit
            </button>
          </div>
        </div>
      </div>

      {loader && (
        <div className="seekoLoader">
          <div className="loader">
            <img src={Logo} alt="loader" />
          </div>
        </div>
      )}
    </>
  );
}
