import React, { useEffect, useState } from "react";
import { NavbarNew } from "./navbar";
import sec1Img from "../../images/homepage/sec1Img.png";
import arrowRight from "../../images/homepage/arrowRight.svg";
import InstrctorIco from "../../images/homepage/InstrctorIco.svg";
import availableIco from "../../images/homepage/availableIco.svg";
import earningIco from "../../images/homepage/earningIco.svg";
import sec3Img1 from "../../images/homepage/sec3Img1.png";
import sec3Img2 from "../../images/homepage/sec3Img2.png";
import sec3Img3 from "../../images/homepage/sec3Img3.png";
import sec3Img4 from "../../images/homepage/sec3Img4.png";

import article1 from "../../images/homepage/article1.jpg";
import article2 from "../../images/homepage/article2.png";
import article3 from "../../images/homepage/article3.png";
import animation1sec1 from "../../images/homepage/animation1sec.mp4";
import animation1sec from "../../images/homepage/animation1sec.webm";
import Animation2WithBg from "../../images/homepage/Animation2WithBg.mp4";
import facebookIco from "../../images/homepage/facebookIco.svg";

import { Link } from "react-router-dom";
import EducationalVideosNew from "./educationalContent";
import { Modal } from "react-bootstrap";
import { SocialModal } from "../modals/socialModal";
import userAction from "../../redux/users/action";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import copy from "copy-to-clipboard";
import api from "../../api";
import { AnnualModal } from "../modals/annualModal";
import { MonthlyModal } from "../modals/monthlyModal";
import { BroadcastModal } from "../modals/broadcastModal";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const HomeNew = () => {
  const userStore = useSelector((state) => state.userReducer);

  const { setUser } = userAction;
  const dispatch = useDispatch();
  const alert = useAlert();
  const [loader, setLoader] = useState(false);
  const [copyText, setCopyText] = useState("Copy to clipboard");
  const [walletInput, setWalletInput] = useState("");

  const [showMore, setShowMore] = useState(false);
  const handleCloseMore = () => setShowMore(false);
  const handleShowMore = () => setShowMore(true);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showAddWallet, setShowAddWallet] = useState(false);
  const handleCloseAddWallet = () => setShowAddWallet(false);
  const handleShowAddWallet = () => setShowAddWallet(true);

  const [showBroadcast, setShowBroadcast] = useState(false);
  const handleCloseBroadcast = () => setShowBroadcast(false);
  const handleShowBroadcast = () => setShowBroadcast(true);

  const [formSubmitted, setformSubmitted] = useState(false);
  const [showYearly, setShowYearly] = useState(false);
  const handleCloseYearly = () => {
    setShowYearly(false);
    setTimeout(() => {
      setformSubmitted(false);
    }, 1000);
  };
  const handleShowYearly = () => {
    setShowYearly(true);
    handleClose();
  };

  const [showMonthly, setShowMonthly] = useState(false);
  const handleCloseMonthly = () => {
    setShowMonthly(false);
    handleShowBroadcast();
  };
  const handleShowMonthly = () => {
    setShowMonthly(true);
    handleClose();
  };

  const [showVideo, setShowVideo] = useState(false);
  const handleCloseVideo = () => {
    setShowVideo(false);
    // {!userStore?.users?.user?.name &&
    //   handleShowWallet(true)
    // }
    handleShowBroadcast();
  };

  const handleCopy = (text) => {
    copy(text);
    setCopyText("Copied");
    setTimeout(() => {
      setCopyText("Copy to clipboard");
    }, 3000);
  };

  const addWallet = async () => {
    try {
      if (!walletInput) {
        alert.show("Please add TRC wallet address");
        return;
      }
      setLoader(true);
      // let balance = await checkBalance(walletInput)
      // console.log(balance);

      // if(balance < 100) {
      //   alert.show("Your do not have enough balance to get VIP access")
      //   setLoader(false)
      //   return
      // }

      let payload = {
        _id: userStore?.users?.user?._id,
        wallet: walletInput,
      };

      const signalResponse = await api.userEdit(payload);
      // console.log(signalResponse);
      if (signalResponse?.success) {
        let updatedData = userStore?.users;
        updatedData.user.wallet = payload.wallet;
        dispatch(setUser(updatedData));
        handleCloseAddWallet();
        alert.show("Your Wallet Address Has Been Added");
        // navigate("/post")
      } else {
        alert.show(signalResponse?.msg);
      }
      setLoader(false);
    } catch (error) {
      console.log(error);
      // handleCloseAddWallet()
      alert.show(error.message);
      setLoader(false);
    }
  };

  useEffect(() => {
    // {!userStore?.users?.user?.name &&
    //   handleShowVideo()
    // }
    // {(userStore?.users?.user?.name && !userStore?.users?.user?.wallet) &&
    //   handleShowAddWallet()
    // }
  }, []);
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 4000,
  };

  return (
    <>
      <div className="homepageNew">
        <div className="homeSec1">
          <div className="container">
            <div className="row ai-center">
              <div className="col-lg-6">
                <div className="content">
                  <div className="tagline">FOR A BETTER FUTURE</div>
                  <div className="heading">
                    Knowledge <span>First</span>, Profits <span>Follow</span>
                  </div>
                  <div className="disclaimer">
                    <span>DISCLAIMER:</span>
                    We are not a university or a degree program and do not claim
                    to replace the value of studying at institutions like
                    Stanford, Harvard, Cambridge, or Oxford. Our course is based
                    on insights from renowned books, including Misbehaving by
                    Richard H. Thaler (Nobel Prize winner), Inside the
                    Investor’s Brain by Richard L. Peterson, Adaptive Markets by
                    Andrew W. Lo, and Thinking, Fast and Slow by Daniel Kahneman
                    (Nobel Prize winner). We also take inspiration from Stanford
                    University’s FINANCE 305: Capital Markets and Institutional
                    Investing and utilize tools like BlackRock’s Aladdin and
                    Bloomberg Terminal for market data analysis.{" "}
                    <div
                      className=" more cursor-pointer"
                      onClick={handleShowMore}
                    >
                      See More
                    </div>
                  </div>
                  <div className="socialModal ms-0 pb-0">
                    <div className="row">
                      <div className="col-6">
                        <Link
                          to={"/signup"}
                          className="monthly socialL"
                          // onClick={handleShowMonthly}
                        >
                          {/* <img src={facebookIco} alt="" /> */}
                          Monthly Group
                        </Link>
                      </div>
                      <div className="col-6">
                        <a
                          className="annual socialL"
                          onClick={handleShowYearly}
                        >
                          <img src={facebookIco} alt="" />
                          Annual Group
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="explore" onClick={handleShow}>
                    <div className="text">Explore Our Channels</div>
                    <div className="next">
                      <img src={arrowRight} alt="mext" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <Slider {...settings}>
                  <div className="imgDiv">
                    {/* <img src={sec1Img} alt="" /> */}
                    <video
                      playsInline
                      webkit-playsinline
                      controls={false}
                      src={animation1sec}
                      autoPlay
                      loop
                      muted
                      className="w-100"
                    />
                  </div>
                  <div className="imgDiv">
                    {/* <img src={sec1Img} alt="" /> */}
                    <video
                      playsInline
                      webkit-playsinline
                      controls={false}
                      src={animation1sec1}
                      autoPlay
                      loop
                      muted
                      className="w-100"
                    />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>

        <section className="articleSec">
          <div className="container">
            <div className="homeHeading text-center">
              Team <span> Acknowledgements</span>
            </div>

            <div className="row jc-center">
              <div className="col-lg-4 mb-3 text-center ">
                <a
                  className="imgDiv"
                  href="https://www.youtube.com/watch?v=eZ0dFzxXTV4"
                  target="_blank"
                >
                  <div className="layout"></div>

                  <img src={article2} alt="" />
                  <div className="content">
                    <div className="date">21 March 2022</div>
                    <div className="title">
                      Crypto Stories: Pakistani crypto billionaire wants his
                      country to be less reliant on credit
                    </div>
                    <div className="desc">
                      A former reality TV host with millions of followers on
                      YouTube became one of Pakistan's biggest....
                    </div>
                    <a className="learnMore">Learn More</a>
                  </div>
                </a>
              </div>

              <div className="col-lg-4 mb-3 text-center">
                <a
                  className="imgDiv"
                  href="https://www.samaa.tv/2087313031-waqar-zaka-makes-millions-amidst-crypto-market-turmoil"
                  target="_blank"
                >
                  <img src={article3} alt="" />
                  <div className="content">
                    <div className="date">14 April 2024</div>
                    <div className="title">
                      Waqar Zaka makes millions amidst Bitcoin crash
                    </div>
                    <a className="learnMore">Learn More</a>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 mb-3 text-center">
                <a
                  className="imgDiv"
                  href="https://dunyanews.tv/amp/english/853790.php"
                  target="_blank"
                >
                  <div className="layout"></div>
                  <img src={article1} alt="" />
                  <div className="content">
                    <div className="date">30 Nov 2024</div>
                    <div className="title">
                      Waqar Zaka continues his profit streak on cryptocurrency.
                    </div>
                    <a className="learnMore">Learn More</a>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="homeSec2">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 mb-4">
                <div className="sec2Ca one">
                  <div className="ico">
                    <img src={InstrctorIco} alt="Instrctor" />
                  </div>
                  <div className="title">Instructors</div>
                  <div className="text">
                    Benefit from 7+ years of experience and the wisdom of our
                    seasoned instructors who are dedicated to guiding and
                    educating you.
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-4">
                <div className="sec2Ca two">
                  <div className="ico">
                    <img src={availableIco} alt="available" />
                  </div>
                  <div className="title">
                    Available During UAE Working Hours
                  </div>
                  <div className="text">
                    Our support team is available to assist you during UAE
                    working hours: 2:00 PM – 11:30 PM UAE Time (GMT+4). Please
                    adjust accordingly to your local time zone for seamless
                    support.
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-4">
                <div className="sec2Ca three">
                  <div className="ico">
                    <img src={earningIco} alt="earning" />
                  </div>
                  <div className="title">Warning</div>
                  <div className="text">
                    Warning: As per 2024 data, only 6.8% of people worldwide are
                    able to engage in and fully understand crypto work.
                    Additionally, 80% to 90% of day traders end up in losses.
                    Why? Because the majority are unwilling to practice demo
                    trades before diving in. If you want to succeed, you must
                    develop patience and focus on practicing trades with minimal
                    investments rather than going all in. Smart and disciplined
                    trading is the key to long term success in the volatile
                    crypto market.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="homeSec3" id="services">
          <div className="container">
            <div className="homeHeading">
              Explore our <span> education services</span>
            </div>
            <div className="row jc-center">
              <div className="col-md-4">
                <div className="sec3Card">
                  <div className="imgDiv">
                    <img src={sec3Img1} alt="" />
                  </div>
                  <div className="title">Block Chain</div>
                  <div className="desc">
                    Learn Blockchain and Revolutionize Possibilities Across
                    Industries!
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="sec3Card">
                  <div className="imgDiv">
                    <img src={sec3Img2} alt="" />
                  </div>
                  <div className="title">Spot Trading</div>
                  <div className="desc">
                    Master the Art of Spot Trading and Ride the Currents of
                    Market Opportunities!
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="sec3Card">
                  <div className="imgDiv">
                    <img src={sec3Img3} alt="" />
                  </div>
                  <div className="title">Future Trading</div>
                  <div className="desc">
                    Dive into the World of Future Trading and Unlock Tomorrow's
                    Profits Today
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="sec3Card">
                  <div className="imgDiv">
                    <img src={sec3Img4} alt="" />
                  </div>
                  <div className="title">
                    Get Education about Stock & Commodities Market
                  </div>
                  <div className="desc">
                    Get Expert education by Team WZ. Learn & Grow!
                  </div>
                </div>
              </div>
              {/* <div className="col-md-4">
                <div className="sec3Card">
                  <div className="imgDiv">
                    <img src={sec3Img5} alt="" />
                  </div>
                  <div className="title">Amazon</div>
                  <div className="desc">
                    Learn Amazon From A to Z and Discover Limitless
                    Opportunities!
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="sec3Card">
                  <div className="imgDiv">
                    <img src={sec3Img6} alt="" />
                  </div>
                  <div className="title">Meta Verse</div>
                  <div className="desc">
                    Step into the Metaverse and Shape Your Own Infinite Digital
                    Universe!
                  </div>
                </div>
              </div> */}
            </div>
            {/* <div className="text-center">
              <button className="themeBlackBtn">See More</button>
            </div> */}
          </div>
        </section>

        <div className="container">
          <section className="homeSec4" id="about">
            <div className="homeHeading">About</div>
            {/* <img className="effect" src={sec4Circle} alt="" /> */}
            <div className="row ai-center">
              <div className="col-lg-7 order-2 order-lg-1">
                <div className="title">
                  <b>We are a team</b> of experienced analysts providing expert
                  insights into blockchain, spot trading, and futures trading.
                  Our goal is to equip you with valuable market analysis,
                  trends, and strategies to help you stay ahead in the crypto
                  space.
                </div>

                <div className="desc mb-0">Stay Informed, Stay Ahead.</div>
                <div className="desc mt-3">
                  While we share in-depth market insights and trading
                  strategies, we are not financial advisors. Our analysis is
                  based on research and industry trends, empowering you to make
                  informed decisions.
                </div>
                <Link className="themeBlackBtn" to={"/signup"}>
                  Enroll Now
                </Link>
              </div>
              <div className="col-lg-5 order-1 order-lg-2">
                <div className="imgDiv">
                  {/* <img src={sec4Img} alt="" /> */}
                  <video
                    playsInline
                    webkit-playsinline
                    controls={false}
                    src={Animation2WithBg}
                    autoPlay
                    loop
                    muted
                    className="w-100"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* <section className="homeSec5">
          <div className="container">
            <div className="homeHeading">Our educational content</div>
            <EducationalVideosNew />
            <div className="text-center mt-4">
              <Link className="themeBlackBtn" to={"/signup"}>
                Enroll Now
              </Link>
            </div>
          </div>
        </section> */}

        {/* <div className="container">
          <section className="homeSec6" id="testimonial">
            <div className="row">
              <div className="col-lg-6">
                <div className="imgdiv">
                  <div className="icon active">
                    <img src={review1} alt="" />
                  </div>
                  <div className="icon one">
                    <img src={review2} alt="" />
                  </div>
                  <div className="icon two">
                    <img src={review3} alt="" />
                  </div>
                  <div className="icon three">
                    <img src={review2} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="reviewIco">
                  <img src={reviewIco} alt="" />
                </div>
                <div className="desc">
                  Since 1985 Reed has pioneered specialist recruitment, Sourcing
                  knowledgeable, skilled professionals pioneered Specialist
                  recruitment, sourcing.
                </div>
                <div className="nameM">
                  <div className="name">Victoria Wotton</div>
                  <div>
                    <div className="desig">Fementum Odio Co.</div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div> */}
      </div>

      <Modal size="lg" show={showMore} onHide={handleCloseMore} centered>
        <Modal.Body>
          <div className="otpModalMain broadcastModal">
            <div className="closeIcon" onClick={handleCloseMore}>
              <i className="fa-solid fa-xmark" />
            </div>

            <div className="showMoreModalM">
              <div className="showMoreModal py-4">
                <p className="desc1">
                  By joining our paid program, you will gain access to real-time
                  investment insights from Waqar Zaka, a day trader and
                  early-stage investor in crypto projects. Some days, you may
                  see multiple spot and futures trades; other times, there may
                  be no trades for weeks—it all depends on market conditions.
                  There is no fixed schedule for trades, and this is not a
                  signal group. Waqar Zaka shares where he is investing, and you
                  must make your own decisions based on your financial knowledge
                  and risk tolerance.
                </p>
                <p className="desc1">
                  This program includes both text-based learning and video
                  content, with live market updates twice a day where we
                  showcase real-time trades. However, we do not guarantee
                  profits or financial success. Like a gym membership, results
                  depend on individual effort—only those who actively learn and
                  apply the knowledge may benefit.
                </p>
                <p className="desc1">
                  Important: We will never ask you to send money to us or any
                  individual claiming to be Waqar Zaka. We are an educational
                  platform, not portfolio managers. If you seek portfolio
                  management services, consider professional firms like
                  BlackRock USA.
                </p>
                <p>
                  No Refund Policy: There are no refunds under any
                  circumstances. It is your responsibility to thoroughly
                  research before purchasing a subscription. This paid service
                  only provides exclusive content on where and how Waqar Zaka is
                  investing—it does not guarantee profits. No one in the world
                  can guarantee returns; if someone claims otherwise, they are
                  scammers. If guaranteed profits were possible, firms like
                  BlackRock would advertise them—yet they don’t, because such
                  guarantees do not exist.
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showAddWallet} onHide={handleCloseAddWallet} centered>
        <Modal.Body>
          <div className="otpModalMain">
            <div className="closeIcon" onClick={handleCloseAddWallet}>
              <i className="fa-solid fa-xmark" />
            </div>

            {/* <h3 className="title color-dark mb-0">For VIP Access</h3> */}
            {/* <p className="teather mb-0 text-center">Please add TRC Wallet Address</p> */}
            {/* <p className='mt-2'>Please note: Access to VIP services requires a minimum wallet balance of 100 USDT. If your balance is below this amount, you will not be able to access VIP services.</p> */}
            <h3 className="title color-dark mb-0">For Copy Trade</h3>
            <p className="mt-2">
              Do you want to copy Waqar Zaka's trades? If yes, then provide the
              address of your USDT (TRC) wallet with a balance of 100 USDT.
            </p>
            <div className="form-group my-4">
              <input
                type="text"
                onChange={(e) => setWalletInput(e.target.value)}
                className="form-control"
                placeholder="Address..."
              />
            </div>
            <div className="text-center">
              <button
                className="default-btn loaderBtn"
                disabled={loader ? true : false}
                onClick={addWallet}
              >
                {loader && <i className="fa fa-spinner fa-pulse mx-2"></i>}
                Add Wallet
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <AnnualModal
        show={showYearly}
        handleClose={handleCloseYearly}
        formSubmitted={formSubmitted}
        setformSubmitted={setformSubmitted}
      />

      <MonthlyModal
        showMonthly={showMonthly}
        handleCloseMonthly={handleCloseMonthly}
      />

      <BroadcastModal
        showBroadcast={showBroadcast}
        handleCloseBroadcast={handleCloseBroadcast}
      />

      <SocialModal
        show={show}
        handleClose={handleClose}
        handleShowMonthly={handleShowMonthly}
        handleShowYearly={handleShowYearly}
      />
    </>
  );
};
