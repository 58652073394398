import { useEffect, useLayoutEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import OneSignal from "react-onesignal";

import "./App.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/css/flaticons.css";
import "./component/global/global.css";
import { navbarAnim } from "./assets/js/custom";

import AOS from "aos";
import "aos/dist/aos.css";
import { Navbar } from "./component/navbar";
import { Footer } from "./component/footer";
import { Home } from "./component/home";
import { Survey } from "./component/survey";
import { Signup } from "./component/signup";
import { Login } from "./component/login";
import api from "./api";
import userAction from "./redux/users/action";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "./component/global/sidebar/sidebar";
import { NavbarAdmin } from "./component/global/navbar/navbar";
import Users from "./component/global/users";
import { AdminLogin } from "./component/global/adminlogin";
import AllSurvey from "./component/global/survey";
import PromotionMail from "./component/global/promotionMail";
import AddPost from "./component/global/addPost";
import AllAdminPost from "./component/global/allPostAdmin";
import { UserPost } from "./component/userPost";
import axios from "axios";
import { DataInfo } from "./component/global/dataInfo";
import { ForgetPass } from "./component/forgetPass";
import { ResetPass } from "./component/resetPass";
import { Portfolio } from "./component/portfolio/portfolio";
import { ConnectPage } from "./component/portfolio/connectPage";
import { PortfolioDashboard } from "./component/portfolio/dashboard/dashboard";
import TupLokersMail from "./component/global/lockersMail";
import LockersList from "./component/global/lockersList";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider } from "wagmi";
import { config } from "./helpers/wagmi";
import WalletUsers from "./component/global/walletUsers";
import { NavbarNew } from "./component/HomeNew/navbar";
import { HomeNew } from "./component/HomeNew";
import { FooterNew } from "./component/HomeNew/footerNew";
import PaidUsers from "./component/global/paidUsersAdmin";
import { Checkout } from "./component/payment/checkout";
import { PayHistoryUser } from "./component/payment/payHistoryUser";
import { AuthNotLoginRoutes, AuthOnlyUserRoutes } from "./helpers/index";
import AdminBroadcast from "./component/global/adminBroadcast";
import { ContactSupport } from "./component/contactSupport";
import ContactSupAdmin from "./component/global/contactSupAdmin";
import AdminVideo from "./component/global/adminvideo";
import { AllAdminStocks } from "./component/global/adminStocks";
import { AllAdminMarket } from "./component/global/adminMarket";
import { CokieModal } from "./component/modals/cokieModal";
import { TermsAndConditions } from "./component/termsAndConditions";
import { PrivacyPolicy } from "./component/privacyPolicy";
import MailToPaidUsers from "./component/global/mailToPaidUser";
import { Loader } from "./component/global/loader";
import UnSubscribedUsers from "./component/global/unsubscribedUsers";
import { SuperAdminLogin } from "./component/global/superAdminLogin";
import AdminYearlyContact from "./component/global/adminYearlyContact";
import { useAlert } from "react-alert";

const queryClient = new QueryClient();

function App() {
  const { setUser, setAdmin } = userAction;
  const dispatch = useDispatch();
  const alert = useAlert();
  const adminStore = useSelector((state) => state.adminReducer);
  const userStore = useSelector((state) => state.userReducer);

  const [loader, setLoader] = useState(false);

  const location = useLocation();
  const [show, setShow] = useState(false);
  const [SubsData, setSubsData] = useState({});
  const [adminType, setAdminType] = useState("admin");

  useEffect(() => {
    navbarAnim();
    AOS.init();
    AOS.refresh();
  }, []);

  const checkUserSession = async () => {
    try {
      let token = localStorage.getItem("token");
      // console.log(token);
      if (token) {
        let payload = { token: token };
        const registerationResponse = await api.verifySession(payload);
        // console.log(registerationResponse);
        if (registerationResponse?.token) {
          axios.defaults.headers.common["Authorization"] =
            registerationResponse?.token;
        } else {
          dispatch(setUser({}));
          setSubsData({});
          localStorage.removeItem("token");
        }
        checkSubs();
      }
      setLoader(false);
    } catch (error) {
      alert.show("Session Expired");
      setLoader(false);
      dispatch(setUser({}));
      setSubsData({});
      localStorage.removeItem("token");
      window.location.href = "/";
    }
  };

  useLayoutEffect(() => {
    checkUserSession();
  }, []);

  const checkSubs = async () => {
    try {
      if (userStore?.users?.user?._id) {
        setLoader(true);
        // console.log("checksubs");

        const res = await api.checkSubscription(userStore?.users?.user?._id);
        // console.log(res);

        if (res?.subscription) {
          let currentDate = new Date();
          let endDate = new Date(res?.subscription?.nextPayment);
          if (currentDate < endDate) {
            res.subscription.isSubscription = true;
            setSubsData(res?.subscription);
          } else {
          }
        }
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    // OneSignal.init({ appId: process.env.REACT_APP_ONESIGNAL_APPID }).then(
    //   () => {
    //     console.log("prompt push notification");
    //     if (window.ios) {
    //       OneSignal.showNativePrompt();
    //     }
    //   }
    // );
  }, []);
  // useEffect(() => {
  //   // Initialize OneSignal with your App ID
  //   OneSignal.init({
  //     appId: process.env.REACT_APP_ONESIGNAL_APPID,
  //     notifyButton: {
  //       enable: true, // Enable OneSignal's notification button
  //     },
  //   });

  //   // Show the notification prompt manually on iOS
  //   if (window && window.ios) {
  //     OneSignal.Notifications.requestPermission();
  //   }
  // }, []);

  const checkSuperAdminSession = async () => {
    try {
      let token = localStorage.getItem("token");
      // console.log(token);
      if (token) {
        let payload = { token: token };
        const registerationResponse = await api.verifySuperAdminSession(
          payload
        );
        // console.log(registerationResponse);
        if (registerationResponse?.token) {
          axios.defaults.headers.common["Authorization"] =
            registerationResponse?.token;
          if (registerationResponse?.role == "superadmin") {
            setAdminType("superadmin");
          }
        } else {
          dispatch(setAdmin({}));
          setSubsData({});
          localStorage.removeItem("token");
        }
      }
      setLoader(false);
    } catch (error) {
      alert.show("Session Expired");
      setLoader(false);
      dispatch(setAdmin({}));
      setSubsData({});
      localStorage.removeItem("token");
      window.location.href = "/";
    }
  };

  useEffect(() => {
    if (adminStore?.admin?.admin?.role == "superadmin") {
      checkSuperAdminSession();
    }
  }, [adminStore]);

  return (
    <div className="App">
      {adminStore?.admin?.admin?.email ? (
        <div id="wrapper">
          <Sidebar adminType={adminType} />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <NavbarAdmin />
              <Routes>
                {adminType == "superadmin" && (
                  <>
                    <Route
                      exact={true}
                      path="/admin/datainfo"
                      element={<DataInfo />}
                    />
                    <Route
                      exact={true}
                      path="/admin/users"
                      element={<Users adminType={adminType} />}
                    />
                    <Route
                      exact={true}
                      path="/admin/wallet-users"
                      element={<WalletUsers />}
                    />
                    <Route
                      exact={true}
                      path="/admin/paid-users"
                      element={<PaidUsers adminType={adminType} />}
                    />
                    <Route
                      exact={true}
                      path="/admin/unsubscribed-users"
                      element={<UnSubscribedUsers />}
                    />
                  </>
                )}
                {/* <Route exact={true} path="/admin/survey" element={<AllSurvey />} /> */}
                <Route
                  exact={true}
                  path="/admin/sendmail"
                  element={<PromotionMail />}
                />
                <Route
                  exact={true}
                  path="/admin/sendmail-paid-users"
                  element={<MailToPaidUsers />}
                />
                {/* <Route exact={true} path="/admin/sendmail-tup-locker" element={<TupLokersMail />} /> */}
                {/* <Route exact={true} path="/admin/tup-locked" element={<LockersList />} /> */}
                <Route
                  exact={true}
                  path="/admin/addpost"
                  element={<AddPost />}
                />
                <Route
                  exact={true}
                  path="/admin/signals"
                  element={<AllAdminPost />}
                />
                <Route
                  exact={true}
                  path="/admin/broadcast"
                  element={<AdminBroadcast />}
                />
                <Route
                  exact={true}
                  path="/admin/video"
                  element={<AdminVideo />}
                />
                <Route
                  exact={true}
                  path="/admin/stocks"
                  element={<AllAdminStocks />}
                />
                <Route
                  exact={true}
                  path="/admin/market"
                  element={<AllAdminMarket />}
                />
                <Route
                  exact={true}
                  path="/admin/contact"
                  element={<ContactSupAdmin />}
                />
                <Route
                  exact={true}
                  path="/admin/yearly-contact-form"
                  element={<AdminYearlyContact />}
                />
              </Routes>
            </div>
          </div>
        </div>
      ) : location.pathname == "/portfolio/connect" ||
        location.pathname.includes("/portfolio/dashboard") ||
        location.pathname.includes("/portfolio/tx") ? (
        <>
          <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}>
              <Routes>
                <Route
                  exact={true}
                  path="/portfolio/connect"
                  element={<ConnectPage />}
                />
                <Route
                  exact={true}
                  path="/portfolio/*"
                  element={<PortfolioDashboard />}
                />
              </Routes>
            </QueryClientProvider>
          </WagmiProvider>
        </>
      ) : (
        <>
          <NavbarNew
            show={show}
            setShow={setShow}
            SubsData={SubsData}
            setSubsData={setSubsData}
          />
          <Routes>
            {/* <Route exact={true} path="/" element={<Home />} /> */}
            <Route exact={true} path="/" element={<HomeNew />} />
            <Route
              exact={true}
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route
              exact={true}
              path="/privacy-policy"
              element={<PrivacyPolicy />}
            />
            {/* <Route exact={true} path="/survey" element={<Survey />} /> */}
            <Route element={<AuthOnlyUserRoutes />}>
              <Route
                exact={true}
                path="/dashboard"
                element={<UserPost SubsData={SubsData} />}
              />
            </Route>
            {/* <Route element={<AuthOnlyUserRoutes />}>
              <Route
                exact={true}
                path="/contact-support"
                element={<ContactSupport SubsData={SubsData} />}
              />
            </Route> */}
            <Route element={<AuthNotLoginRoutes />}>
              <Route exact={true} path="/signup" element={<Signup />} />
            </Route>
            <Route element={<AuthNotLoginRoutes />}>
              <Route
                exact={true}
                path="/login"
                element={<Login setSubsData={setSubsData} />}
              />
            </Route>
            <Route element={<AuthNotLoginRoutes />}>
              <Route
                exact={true}
                path="/forget-password"
                element={<ForgetPass />}
              />
            </Route>
            <Route element={<AuthNotLoginRoutes />}>
              <Route
                exact={true}
                path="/reset-password/:id"
                element={<ResetPass />}
              />
            </Route>
            <Route element={<AuthOnlyUserRoutes />}>
              <Route
                exact={true}
                path="/checkout"
                element={<Checkout SubsData={SubsData} />}
              />
            </Route>
            <Route element={<AuthOnlyUserRoutes />}>
              <Route
                exact={true}
                path="/subscription/history"
                element={
                  <PayHistoryUser
                    SubsData={SubsData}
                    setSubsData={setSubsData}
                  />
                }
              />
            </Route>
            <Route exact={true} path="/admin-login" element={<AdminLogin />} />
            <Route
              exact={true}
              path="/super-admin-login"
              element={<SuperAdminLogin setAdminType={setAdminType} />}
            />
            <Route exact={true} path="/portfolio" element={<Portfolio />} />
          </Routes>
          <FooterNew show={show} setShow={setShow} />
          {/* {location.pathname == "/home" ? (
          ) : (
            <Footer show={show} setShow={setShow} />
          )} */}
        </>
      )}
      <CokieModal />

      {loader && <Loader />}
    </div>
  );
}

export default App;
