export const courseData1 = [
  {
    title:
      "Post ko end tak see more per click kar k achi tarah samja lena hai.",
    link: "https://facebook.com/groups/waqarzakatechnologymovementpakistan/posts/2044597862692645/",
  },
  {
    title:
      "Roz sirf 30 minute dimagh ki yeh exercises karo aur smart trader ban jao simple aur effective!",
    link: "https://www.facebook.com/share/p/1GRC16SYCG/",
  },
  {
    title: "Dark pool Kia hai? Paisa banane k Liya",
    link: "https://www.facebook.com/share/p/1BBEoqbJQv/",
  },
  {
    title: "How to build Muscle Memory? Book , Thinking  Fast and Slow Guide",
    link: "https://www.facebook.com/share/p/15nWN7asMN/",
  },
  {
    title: "Halal Haram Future Trading Ultimate Guide.",
    link: "https://www.facebook.com/share/p/cg9zvwrmL8nXvnvt/",
  },
  {
    title: "Sub sey important yeh Nahie samja tu scene kharab hai ",
    link: "https://www.facebook.com/share/p/1FGo7EZDqg/",
  },
  {
    title:
      "Emotions k Sath Trading Karna Neuro economics ki science by Waqar Zaka ",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/posts/2046208865864878/",
  },
  {
    title:
      "Coin ki selection karna 15 minutes mei profit karney k Liya ? Possible ? Let's discover.",
    link: "https://www.facebook.com/share/p/15Qfbrqg8S/?",
  },
  {
    title: "Demo Trade K Liye Checklist and method ",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/posts/2044666909352407/",
  },
  {
    title: "Future trading k Liya Waqar Zaka kesay Coin select karta hai ? ",
    link: "https://www.facebook.com/share/p/14UxY1oWFB/",
  },
  {
    title: "Profitable Coin Kaise Search Karein? Easy steps for new comer",
    link: "https://www.facebook.com/share/p/19Re533QT1/?",
  },
  {
    title: "Daily profit banana hai tu yeh exercise start karlo",
    link: "https://www.facebook.com/share/p/1B5XmH14M5/",
  },
  {
    title: "Leverages traders ki exercise",
    link: "https://www.facebook.com/share/p/1Ewn7nrAoV/",
  },
  {
    title: "How waqarzaka make profit using RSI?",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/posts/2044456956040069/",
  },
  {
    title: "What is a Exchange?",
    link: "https://www.facebook.com/waqarzaka/videos/597929235762486/?idorvanity=932060957279680",
  },
  {
    title: "What is wallet?",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/permalink/1449362605549510/?app=fbl",
  },
  {
    title: "How to register at Bitget?",
    link: "https://fb.watch/vT1qIAl-Ha/",
  },
  {
    title: "How to transfer funds to Bitget?",
    link: "https://fb.watch/vT1vA0xc1g/",
  },
  {
    title: "How to do Spot,Futures and Demo trading?",
    link: "https://fb.watch/vT1wnaYy7V/",
  },
  {
    title: "How to calculate liquidation price?",
    link: "https://fb.watch/vT1xpbYFaP/",
  },
  {
    title: "How to use Bitget Support for any issue?",
    link: "https://fb.watch/vT1yai8SmR/",
  },
  {
    title: "Trading View",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=3128029824168453&post=945027726650336",
  },
  {
    title: "Trading Basics",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=716724992735762",
  },
  {
    title: "Portfolio Management",
    link: "https://fb.watch/vT1CSpTYhy/",
  },
  {
    title: "Candlesticks and Price action",
    link: "https://fb.watch/vT1DOi7nw-/",
  },
  {
    title: "Future trading and practice",
    link: "https://fb.watch/vT1EupRNK5/",
  },
  {
    title: "Support and Resistance",
    link: "https://fb.watch/vT1FdiXwEE/",
  },
  {
    title: "Chart patterns",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content?filter=567843461716238&post=837996643852624,https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=1460669514406902,https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=801868520831945",
  },
  {
    title: "RSI",
    link: "https://fb.watch/vT1JRoAeho/",
  },
  {
    title: "ATR",
    link: "https://fb.watch/vT1KCESt-c/",
  },
];

export const courseDataMonthly = [
  {
    title: "Welcome",
    link: "https://fb.watch/xr3XxejlAd/",
  },
  {
    title: "How to judge Winter mute and other  market markers? ",
    link: "https://www.facebook.com/share/p/1BH88VtHEz/?",
  },
  {
    title: "M1 , M2 ,M3 for Money making explained",
    link: "https://www.facebook.com/share/p/18SmDuZWfw/?",
  },
  {
    title: "Complete A to Z course",
    link: "https://www.facebook.com/share/p/18341Z1WEg/?",
  },
  {
    title: "How to use this group",
    link: "https://facebook.com/groups/waqarzakatechnologymovementpakistan/posts/2044597862692645/",
  },
  {
    title:
      "Roz sirf 30 minute dimagh ki yeh excercises karo aur smart trader banjao simple aur effective!",
    link: "https://www.facebook.com/share/p/1GRC16SYCG/",
  },
  {
    title: "How to build muscle memory? Thinking Fast and Slow guide",
    link: "https://www.facebook.com/share/p/15nWN7asMN/",
  },
  {
    title: "Halal and Haram Future Ultimate Guide",
    link: "https://www.facebook.com/share/p/cg9zvwrmL8nXvnvt/",
  },
  {
    title: "Sab sa important yeh nahi samja toh scene kharab ha",
    link: "https://www.facebook.com/share/p/1FGo7EZDqg/",
  },
  {
    title:
      "Emotions k sath trading kesay karni ha? Neuro economics ki science by Waqar Zaka",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/posts/2046208865864878/",
  },
  {
    title:
      "Coin ki selection kesay karni ha 15 minute me profit karnay k liye? Lets discover",
    link: "https://www.facebook.com/share/p/15Qfbrqg8S/?",
  },
  {
    title: "Demo Trading kesay karni ha",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/posts/2044666909352407/",
  },
  {
    title:
      "Spot k liye ziada profit walay coins kesay dhundnay hain? Complete guide",
    link: "https://www.facebook.com/share/p/19Re533QT1/?",
  },
  {
    title:
      "Bitget Par Apna Account Kaise Register Karain? – Step-by-Step Guide!",
    link: "https://fb.watch/xsMGmoX82a/",
  },
  {
    title: "Exchange Se Funds Transfer Karne Ka Asaan Tareeqa!",
    link: "https://fb.watch/xsMGMTaT4H/",
  },
  {
    title: "Bitget Par Trading Kaise Karein?",
    link: "https://fb.watch/xsMHktQoHl/",
  },
  {
    title: "Bitget Support Ka Sahi Istemaal",
    link: "https://fb.watch/xsMIqT_xKg/",
  },
  {
    title: "Trading Ke Mukhtalif Andaaz – Konsa Best Hai Aapke Liye?",
    link: "https://fb.watch/xsMKFgcl1J/",
  },
  {
    title: "Safe Trader Ka Raaz!",
    link: "https://fb.watch/xsMLq4erMa/",
  },
  {
    title: "Price Action Ko Samjhein!",
    link: "https://fb.watch/xsML_M7cFO/",
  },
  {
    title: "Market Har Halat Mein Jeetain!",
    link: "https://fb.watch/xsMMubIMVQ/",
  },
  {
    title: "Entry Aur Exit Ka Sahi Waqt!",
    link: "https://fb.watch/xsMN0wzDzO/",
  },
  {
    title: "Chart patterns 1",
    link: "https://fb.watch/xsMNAoqCtZ/",
  },
  {
    title: "Chart patterns 2",
    link: "https://fb.watch/xsMOhdfJ00/",
  },
  {
    title: "Chart patterns 3",
    link: "https://fb.watch/xsMOOXOk0o/",
  },
  {
    title: "Chart patterns 4",
    link: "https://fb.watch/xsMPgK65mq/",
  },
  {
    title: "Chart patterns 5",
    link: "https://fb.watch/xsMPOZOIb4/",
  },
  {
    title: "Chart patterns 6",
    link: "https://fb.watch/xsMQeh_t1n/",
  },
  {
    title: "Chart patterns 7",
    link: "https://fb.watch/xsMQR4KEYA/",
  },
  {
    title: "Overbought Aur Oversold Ka Pata Lagain!",
    link: "https://fb.watch/xsMRGXIOdU/",
  },
  {
    title: "Market Ki Volatility Ko Samjhein!",
    link: "https://fb.watch/xsMS8W3EUO/",
  },
  {
    title: "Smart Exit Strategy!",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=432794568841299&post=841089307078337",
  },
  {
    title: "Market Mein Paisa Kahan Jaa Raha Hai?",
    link: "https://fb.watch/xxPIQOV5Me/",
  },
  {
    title: "Apne Profits Aur Loss Ka Sahi Hisab!",
    link: "https://fb.watch/xxPKLZmVci/",
  },
  {
    title: "Market Ke Trend Ka Powerful Signal!",
    link: "https://fb.watch/xxPFWr8TCe/",
  },
  {
    title: "Trend Ka Ultimate Indicator!",
    link: "https://fb.watch/xxPEwSboPy/",
  },
  {
    title: "Bull Run Ka Aaghaz!",
    link: "https://fb.watch/xxPDjKzJ3e/",
  },
  {
    title: "Market Crash Ka Pehla Signal!",
    link: "https://fb.watch/xxPB_grlsN/",
  },
  {
    title: "Trend Ko Pakrain Aur Jeetain!",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=183797814390264&post=1149499779050468",
  },
  {
    title: "Blockchain Kya Hai? – Digital Duniya Ka Mustaqbil!",
    link: "https://fb.watch/xsNerPxwaH/",
  },
  {
    title: "Blockchain Lesson 1",
    link: "https://www.facebook.com/100000853980658/videos/4238116386226786/",
  },
  {
    title: "Blockchain Lesson 2",
    link: "https://www.facebook.com/100000853980658/videos/4246261785412246/",
  },
  {
    title: "Blockchain Lesson 3",
    link: "https://www.facebook.com/100000853980658/videos/4265657883472636/",
  },
  {
    title: "Blockchain Lesson 4",
    link: "https://www.facebook.com/100000853980658/videos/2864073450476747/",
  },
  {
    title: "Blockchain Lesson 5",
    link: "https://www.facebook.com/100000853980658/videos/324749975973920/",
  },
  {
    title: "Blockchain Lesson 6",
    link: "https://www.facebook.com/100000853980658/videos/3592260350998634/",
  },
  {
    title: "Blockchain Lesson 7",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=550667219400280",
  },
  {
    title: "Blockchain Lesson 8",
    link: "https://www.facebook.com/100000853980658/videos/313383640163088/",
  },
  {
    title: "Blockchain Lesson 9",
    link: "https://www.facebook.com/100006395763387/videos/616928746144072/",
  },
  {
    title: "Blockchain Lesson 10",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=155714276730528",
  },
  {
    title: "Blockchain Lesson 11",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=554780999164169",
  },
  {
    title: "Blockchain Lesson 12",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=694810251499704",
  },
  {
    title: "Blockchain Lesson 13",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=327172969167414",
  },
  {
    title: "Blockchain Lesson 14",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=2673190429493097",
  },
  {
    title: "Blockchain Lesson 15",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=394392149006560",
  },
  {
    title: "Smart Contracts Lesson 1",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=743410550161480",
  },
  {
    title: "Smart Contracts Lesson 2",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=794224938234333",
  },
  {
    title: "Smart Contracts Lesson 3",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=433108415025113",
  },
  {
    title: "Smart Contracts Lesson 4",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=396392372305135",
  },
  {
    title: "Smart Contracts Lesson 5",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=4817595151679395",
  },
  {
    title: "Smart Contracts Lesson 6",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=3265395383741687",
  },
  {
    title: "Smart Contracts Lesson 7",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=716492632770167",
  },
  {
    title: "Smart Contracts Lesson 8",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=464322751722803",
  },
  {
    title: "Smart Contracts Lesson 9",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=348249514147797",
  },
  {
    title: "Smart Contracts Lesson 10",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=1106872563369520",
  },
  {
    title: "Smart Contracts Lesson 11",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=5755545804479266",
  },
  {
    title: "Smart Contracts Lesson 12",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=752602629330969",
  },
  {
    title: "Smart Contracts Lesson 13",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=783787625986151",
  },
  {
    title: "Smart Contracts Lesson 14",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=857612015374582",
  },
  {
    title: "Smart Contracts Lesson 15",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=490201485888900",
  },
  {
    title: "Smart Contracts Lesson 16",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=1499694730480133",
  },
  {
    title: "Smart Contracts Lesson 17",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=1832740150413645",
  },
  {
    title: "Smart Contracts Lesson 18",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=1139737323331176",
  },
  {
    title: "Smart Contracts Lesson 19",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=3203143013281189",
  },
  {
    title: "Smart Contracts Lesson 20",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=810454306884990",
  },
  {
    title: "Smart Contracts Lesson 21",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=530115632438312",
  },
  {
    title: "Smart Contracts Lesson 22",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=787991545593965",
  },
  {
    title: "Smart Contracts Lesson 23",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=572647994433260",
  },
  {
    title: "Smart Contracts Lesson 24",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=585964776713339",
  },
  {
    title: "Smart Contracts Lesson 25",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=806682277445327",
  },
  {
    title: "Smart Contracts Lesson 26",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=900628971122671",
  },
  {
    title: "Smart Contracts Lesson 27",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=175575491882015",
  },
  {
    title: "Smart Contracts Lesson 28",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=981868393196955",
  },
  {
    title: "Smart Contracts Lesson 29",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=3370437199864964",
  },
  {
    title: "Smart Contracts Lesson 30",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=583652507048379",
  },
  {
    title: "Smart Contracts Lesson 31",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=268892515625561",
  },
  {
    title: "Smart Contracts Lesson 32",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=1343345429729305",
  },
  {
    title: "Smart Contracts Lesson 33",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=2317617701755561",
  },
  {
    title: "Smart Contracts Lesson 34",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=1443228446429223",
  },
  {
    title: "Smart Contracts Lesson 35",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=266859929292705",
  },
  {
    title: "Smart Contracts Lesson 36",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=3385423201721394",
  },
  {
    title: "Smart Contracts Lesson 37",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=707796594506516",
  },
  {
    title: "Smart Contracts Lesson 38",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=620885926818049",
  },
  {
    title: "Smart Contracts Lesson 39",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=669236778422838",
  },
  {
    title: "Smart Contracts Lesson 40",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=1344582103145382",
  },
  {
    title: "Smart Contracts Lesson 42",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=978294940061909",
  },
  {
    title: "Smart Contracts Lesson 43",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=314356518036549",
  },
  {
    title: "Smart Contracts Lesson 44",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=545985417750002",
  },
];

export const courseDataMonthlyGuide1 = [
  {
    title: "Lesson 1",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=734334721741684&post=1441118890080260",
  },
  {
    title: "Lesson 2",
    link: "https://facebook.com/groups/waqarzakatechnologymovementpakistan/posts/2044597862692645/",
  },
  {
    title: "Lesson 3",
    link: "https://www.facebook.com/share/p/1GRC16SYCG/",
  },
  {
    title: "Lesson 4",
    link: "https://www.facebook.com/share/p/15nWN7asMN/",
  },
  {
    title: "Lesson 5",
    link: "https://www.facebook.com/share/p/cg9zvwrmL8nXvnvt/",
  },
  {
    title: "Lesson 6",
    link: "https://www.facebook.com/share/p/1FGo7EZDqg/",
  },
  {
    title: "Lesson 7",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/posts/2046208865864878/",
  },
  {
    title: "Lesson 8",
    link: "https://www.facebook.com/share/p/15Qfbrqg8S/?",
  },
  {
    title: "Lesson 9",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/posts/2044666909352407/",
  },
  {
    title: "Lesson 10",
    link: "https://www.facebook.com/share/p/19Re533QT1/?",
  },
];

export const courseDataMonthlyGuide2 = [
  {
    title: "Lesson 1",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=769690621017060&post=1686651868382407",
  },
  {
    title: "Lesson 2",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=769690621017060&post=395692709362344",
  },
  {
    title: "Lesson 3",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=769690621017060&post=367452355593520",
  },
  {
    title: "Lesson 4",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=769690621017060&post=3211839742412325",
  },
];
export const courseDataMonthlyGuide3 = [
  {
    title: "Lesson 1",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=716724992735762",
  },
  {
    title: "Lesson 2",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=598043685362237",
  },
  {
    title: "Lesson 3",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=1906103036792079",
  },
  {
    title: "Lesson 4",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=414550740489852",
  },
  {
    title: "Lesson 5",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=764285741279770",
  },
  {
    title: "Lesson 6",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=837996643852624",
  },
  {
    title: "Lesson 7",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=1460669514406902",
  },
  {
    title: "Lesson 8",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=1119127712287311",
  },
  {
    title: "Lesson 9",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=801868520831945",
  },
  {
    title: "Lesson 10",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=743213373427425",
  },
  {
    title: "Lesson 11",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=749119553035469",
  },
  {
    title: "Lesson 12",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=3178049332434646",
  },
  {
    title: "Lesson 13",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=1190207468371870",
  },
  {
    title: "Lesson 14",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=733427481075059",
  },
  {
    title: "Lesson 15",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=432794568841299&post=841089307078337",
  },
  {
    title: "Lesson 16",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=432794568841299&post=645810237026213",
  },
  {
    title: "Lesson 17",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=432794568841299&post=5567454706673062",
  },
  {
    title: "Lesson 18",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=183797814390264&post=219331154286176",
  },
  {
    title: "Lesson 19",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=183797814390264&post=757769235858053",
  },
  {
    title: "Lesson 20",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=183797814390264&post=230459739544849",
  },
  {
    title: "Lesson 21",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=183797814390264&post=1300390880822621",
  },
  {
    title: "Lesson 22",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=183797814390264&post=1149499779050468",
  },
];

export const courseDataMonthlyGuide4 = [
  {
    title: "Lesson 1",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=493894868561317",
  },
  {
    title: "Lesson 2 ",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=514545526633859",
  },
  {
    title: "Lesson 3 ",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=788318128716246",
  },
  {
    title: "Lesson 4 ",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=363082595152415",
  },
  {
    title: "Lesson 5 ",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=320441303070089",
  },
  {
    title: "Lesson 6",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=337268464618120",
  },
  {
    title: "Lesson 7",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=550667219400280",
  },
  {
    title: "Lesson 8",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=550667219400280",
  },
  {
    title: "Lesson 9",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=189211189893643",
  },
  {
    title: "Lesson 10",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=1169626240213569",
  },
  {
    title: "Lesson 11",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=155714276730528",
  },
  {
    title: "Lesson 12",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=554780999164169",
  },
  {
    title: "Lesson 13",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=694810251499704",
  },
  {
    title: "Lesson 14",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=327172969167414",
  },
  {
    title: "Lesson 15",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=2673190429493097",
  },
  {
    title: "Lesson 16",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=394392149006560",
  },
];

export const courseDataMonthlyGuide5 = [
  {
    title: "Lesson 1",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=743410550161480",
  },
  {
    title: "Lesson 2",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=794224938234333",
  },
  {
    title: "Lesson 3",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=433108415025113",
  },
  {
    title: "Lesson 4",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=396392372305135",
  },
  {
    title: "Lesson 5",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=4817595151679395",
  },
  {
    title: "Lesson 6",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=3265395383741687",
  },
  {
    title: "Lesson 7",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=716492632770167",
  },
  {
    title: "Lesson 8",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=464322751722803",
  },
  {
    title: "Lesson 9",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=348249514147797",
  },
  {
    title: "Lesson 10",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=1106872563369520",
  },
  {
    title: "Lesson 11",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=5755545804479266",
  },
  {
    title: "Lesson 12",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=752602629330969",
  },
  {
    title: "Lesson 13",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=783787625986151",
  },
  {
    title: "Lesson 14",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=857612015374582",
  },
  {
    title: "Lesson 15",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=490201485888900",
  },
  {
    title: "Lesson 16",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=1499694730480133",
  },
  {
    title: "Lesson 17",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=1832740150413645",
  },
  {
    title: "Lesson 18",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=1139737323331176",
  },
  {
    title: "Lesson 19",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=3203143013281189",
  },
  {
    title: "Lesson 20",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=810454306884990",
  },
  {
    title: "Lesson 21",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=530115632438312",
  },
  {
    title: "Lesson 22",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=787991545593965",
  },
];

export const courseDataYearly = [
  {
    title: "Welcome",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=3368283383435095&post=6566806313436323",
  },
  {
    title: "How to use this group",
    link: "https://www.facebook.com/share/v/19dmXo3Ju5/?",
  },
  {
    title:
      "Roz sirf 30 minute dimagh ki yeh excercises karo aur smart trader banjao simple aur effective!",
    link: "https://www.facebook.com/share/p/15rw5aP3m9/?",
  },
  {
    title: "Yearly group M1 , M2, M3 for Money making explained ",
    link: "https://www.facebook.com/share/p/1BPPa8WCPK/?",
  },
  {
    title: "How to judge Winter mute and other  market markers? ",
    link: "https://www.facebook.com/share/p/1BH88VtHEz/?",
  },
  {
    title: "How to build muscle memory? Thinking Fast and Slow guide",
    link: "https://www.facebook.com/share/p/14XBoRcpAP/?",
  },
  {
    title: "Halal and Haram Future Ultimate Guide",
    link: "https://www.facebook.com/share/p/15E7yrm7oc/?",
  },
  {
    title: "Sab sa important yeh nahi samja toh scene kharab ha",
    link: "https://www.facebook.com/share/p/1KdiqyVQH7/?",
  },
  {
    title:
      "Emotions k sath trading kesay karni ha? Neuro economics ki science by Waqar Zaka",
    link: "https://www.facebook.com/share/p/1CwL2U1pXS/?",
  },
  {
    title:
      "Coin ki selection kesay karni ha 15 minute me profit karnay k liye? Lets discover",
    link: "https://www.facebook.com/share/p/1KAxhYY4iK/?",
  },
  {
    title: "Demo Trading kesay karni ha",
    link: "https://www.facebook.com/share/p/15fktX2cL1/?",
  },
  {
    title:
      "Spot k liye ziada profit walay coins kesay dhundnay hain? Complete guide",
    link: "https://www.facebook.com/share/p/1BQpbYWu2k/?",
  },
  {
    title:
      "Bitget Par Apna Account Kaise Register Karain? – Step-by-Step Guide!",
    link: "https://fb.watch/xxQP-L2BaQ/",
  },
  {
    title: "Exchange Se Funds Transfer Karne Ka Asaan Tareeqa!",
    link: "https://fb.watch/xxQQGYkwzS/",
  },
  {
    title: "Bitget Par Trading Kaise Karein?",
    link: "https://fb.watch/xxQReLpxXg/",
  },
  {
    title: "Bitget Support Ka Sahi Istemaal",
    link: "https://fb.watch/xxQRTEfCYo/",
  },
  {
    title: "Trading Ke Mukhtalif Andaaz – Konsa Best Hai Aapke Liye?",
    link: "https://fb.watch/xxQEN3BV9e/",
  },
  {
    title: "Safe Trader Ka Raaz!",
    link: "https://fb.watch/xxQE1RwlUM/",
  },
  {
    title: "Price Action Ko Samjhein!",
    link: "https://fb.watch/xxQFvt3Isx/",
  },
  {
    title: "Market Har Halat Mein Jeetain!",
    link: "https://fb.watch/xxQG64Omih/",
  },
  {
    title: "Entry Aur Exit Ka Sahi Waqt!",
    link: "https://fb.watch/xxQGR0GYUM/",
  },
  {
    title: "Chart patterns 1",
    link: "https://fb.watch/xxQIF2gxaU/",
  },
  {
    title: "Chart patterns 2",
    link: "https://fb.watch/xxQJlfVTou/",
  },
  {
    title: "Chart patterns 3",
    link: "https://fb.watch/xxQJUEEQOh/",
  },
  {
    title: "Chart patterns 4",
    link: "https://fb.watch/xxQKsrI6hN/",
  },
  {
    title: "Chart patterns 5",
    link: "https://fb.watch/xxQL1jbajT/",
  },
  {
    title: "Chart patterns 6",
    link: "https://fb.watch/xxQLz7OG83/",
  },
  {
    title: "Chart patterns 7",
    link: "https://fb.watch/xxQM3dlwI3/",
  },
  {
    title: "Overbought Aur Oversold Ka Pata Lagain!",
    link: "https://fb.watch/xxQMBI6zjA/",
  },
  {
    title: "Market Ki Volatility Ko Samjhein!",
    link: "https://fb.watch/xxQHPgNkUk/",
  },
  {
    title: "Smart Exit Strategy!",
    link: "https://www.facebook.com/100025467665160/videos/677842953713538/",
  },
  {
    title: "Market Mein Paisa Kahan Jaa Raha Hai?",
    link: "https://fb.watch/xsPf-bj_NW/",
  },
  {
    title: "Apne Profits Aur Loss Ka Sahi Hisab!",
    link: "https://fb.watch/xsPn9crhkE/",
  },
  {
    title: "Market Ke Trend Ka Powerful Signal!",
    link: "https://fb.watch/xsPrMalqHn/",
  },
  {
    title: "Trend Ka Ultimate Indicator!",
    link: "https://fb.watch/xsPrMalqHn/",
  },
  {
    title: "Bull Run Ka Aaghaz!",
    link: "https://fb.watch/xsPAjAX4ST/",
  },
  {
    title: "Market Crash Ka Pehla Signal!",
    link: "https://fb.watch/xsPy2L-ON0/",
  },
  {
    title: "Trend Ko Pakrain Aur Jeetain!",
    link: "https://fb.watch/xsPCauCUFf/",
  },
  {
    title: "Blockchain Kya Hai? – Digital Duniya Ka Mustaqbil!",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=189521656420873&post=321089749475586",
  },
  {
    title: "Blockchain Lesson 1",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=189521656420873&post=4064394436969466",
  },
  {
    title: "Blockchain Lesson 2",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=189521656420873&post=870291383830746",
  },
  {
    title: "Blockchain Lesson 3",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=189521656420873&post=185601320080306",
  },
  {
    title: "Blockchain Lesson 4",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=189521656420873&post=4798604150169715",
  },
  {
    title: "Blockchain Lesson 5",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=189521656420873&post=958071135017426",
  },
  {
    title: "Blockchain Lesson 6",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=189521656420873&post=215153817198298",
  },
  {
    title: "Blockchain Lesson 7",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=189521656420873&post=377059430452789",
  },
  {
    title: "Blockchain Lesson 8",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=189521656420873&post=797587537554933",
  },
];
export const courseDataYearlyGuide1 = [
  {
    title: "Introduction",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=3368283383435095&post=6566806313436323",
  },
  {
    title: "How to use Group?",
    link: "https://www.facebook.com/share/v/19dmXo3Ju5/?",
  },
  {
    title: "Brain excercises",
    link: "https://www.facebook.com/share/p/15rw5aP3m9/?",
  },
  {
    title: "Build Muscle Memory",
    link: "https://www.facebook.com/share/p/14XBoRcpAP/?",
  },
  {
    title: "Halal and Haram in Crypto",
    link: "https://www.facebook.com/share/p/15E7yrm7oc/?",
  },
  {
    title: "Wallet Guide",
    link: "https://www.facebook.com/share/p/1KdiqyVQH7/?",
  },
  {
    title: "How to Manage Emotions?",
    link: "https://www.facebook.com/share/p/1CwL2U1pXS/?",
  },
  {
    title: "Coin selection Tips",
    link: "https://www.facebook.com/share/p/1KAxhYY4iK/?",
  },
  {
    title: "Demo Trading Tips",
    link: "https://www.facebook.com/share/p/15fktX2cL1/?",
  },
  {
    title: "How to find profitable coins?",
    link: "https://www.facebook.com/share/p/1BQpbYWu2k/?",
  },
];
export const courseDataYearlyGuide2 = [
  {
    title: "how to register your account at bitget?",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=6077773398948094&post=260839136598415",
  },
  {
    title: "How to transfer funds from exchange?",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=829027005145123&post=2008136536053943",
  },
  {
    title: "how to trade at bitget?",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=829027005145123&post=602919301277511",
  },
  {
    title: "How to use bitget support?",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=829027005145123&post=485013086993467",
  },
];
export const courseDataYearlyGuide3 = [
  {
    title: "Basics",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=831034011210822&post=1467504803695087",
  },
  {
    title: "Types of trading,markets and what is DCA",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=831034011210822&post=729378421681237",
  },
  {
    title: "Portfolio Management and Risk management",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=831034011210822&post=1759634054372297",
  },
  {
    title: "Candlesticks and price action trading",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=831034011210822&post=561446165621652",
  },
  {
    title: "Long & Short positions",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=831034011210822&post=462053649097982",
  },
  {
    title: "Support & Resistance",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=831034011210822&post=749580986291437",
  },
  {
    title: "Chart patterns 1",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=831034011210822&post=578381407106697",
  },
  {
    title: "Chart patterns 2",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=831034011210822&post=1063577657620135",
  },
  {
    title: "Chart patterns 3",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=831034011210822&post=3230924973831183",
  },
  {
    title: "Chart patterns 4",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=831034011210822&post=1276850623070770",
  },
  {
    title: "Chart patterns 5",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=831034011210822&post=507212134549476",
  },
  {
    title: "Chart patterns 6",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=831034011210822&post=2872478139715089",
  },
  {
    title: "Chart patterns 7",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=831034011210822&post=5868009633228803",
  },
  {
    title: "RSI/ STOCH RSI",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=831034011210822&post=664525205393417",
  },
  {
    title: "ATR",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=831034011210822&post=402575111762849",
  },
  {
    title: "OCO/Trailing stop",
    link: "https://www.facebook.com/100025467665160/videos/677842953713538/",
  },
  {
    title: "Money Flow",
    link: "https://fb.watch/xeqllsYvQt/",
  },
  {
    title: "How to calculate PNL?",
    link: "https://fb.watch/xeqmAG9eP4/",
  },
  {
    title: "Dial Indicator",
    link: "https://fb.watch/xeqoVaJoeQ/",
  },
  {
    title: "MACD",
    link: "https://fb.watch/xeqjl-I6Ju/",
  },
  {
    title: "Golden Cross",
    link: "https://fb.watch/xeqb1cn3gH/",
  },
  {
    title: "Death Cross",
    link: "https://fb.watch/xeqad_Wt9d/",
  },
  {
    title: "EMA",
    link: "https://fb.watch/xeq7s6rx3I/",
  },
];
export const courseDataYearlyGuide4 = [
  {
    title: "Lesson 1",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=189521656420873&post=321089749475586",
  },
  {
    title: "Lesson 2 ",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=189521656420873&post=4064394436969466",
  },
  {
    title: "Lesson 3 ",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=189521656420873&post=870291383830746",
  },
  {
    title: "Lesson 4 ",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=189521656420873&post=185601320080306",
  },
  {
    title: "Lesson 5 ",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=189521656420873&post=4798604150169715",
  },
  {
    title: "Lesson 6",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=189521656420873&post=958071135017426",
  },
  {
    title: "Lesson 7",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=189521656420873&post=215153817198298",
  },
  {
    title: "Lesson 8",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=189521656420873&post=377059430452789",
  },
  {
    title: "Lesson 9",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=189521656420873&post=797587537554933",
  },
];
export const courseDataAdvanceCBD = [
  {
    title: "Introduction",
    link: "https://fb.watch/wtceK5Z9nW/,https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=493894868561317",
  },
  {
    title: "Lesson 1",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=514545526633859",
  },
  {
    title: "Lesson 2",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=788318128716246",
  },
  {
    title: "Lesson 3",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=363082595152415",
  },
  {
    title: "Lesson 4",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=320441303070089",
  },
  {
    title: "Lesson 5",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=337268464618120",
  },
  {
    title: "Lesson 6",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=716492632770167",
  },
  {
    title: "Lesson 7",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=550667219400280",
  },
  {
    title: "Lesson 8",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=189211189893643",
  },
  {
    title: "Lesson 9",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=1169626240213569",
  },
  {
    title: "Lesson 10",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=155714276730528",
  },
  {
    title: "Lesson 11",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=554780999164169",
  },
  {
    title: "Lesson 12",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=694810251499704",
  },
  {
    title: "Lesson 13",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=327172969167414",
  },
  {
    title: "Lesson 14",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=2673190429493097",
  },
  {
    title: "Lesson 15",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=394392149006560",
  },
];
export const courseDataAdvanceSCD = [
  {
    title: "Lecture 1",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=743410550161480",
  },
  {
    title: "Lecture 2",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=794224938234333",
  },
  {
    title: "Lecture 3",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=433108415025113",
  },
  {
    title: "Lecture 4",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=396392372305135",
  },
  {
    title: "Lecture 5",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=4817595151679395",
  },
  {
    title: "Lecture 6",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=3265395383741687",
  },
  {
    title: "Lecture 7",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=716492632770167",
  },
  {
    title: "Lecture 8",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=464322751722803",
  },
  {
    title: "Lecture 9",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=348249514147797",
  },
  {
    title: "Lecture 10",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=1106872563369520",
  },
  {
    title: "Lecture 11",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=5755545804479266",
  },
  {
    title: "Lecture 12",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=752602629330969",
  },
  {
    title: "Lecture 13",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=783787625986151",
  },
  {
    title: "Lecture 14",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=857612015374582",
  },
  {
    title: "Lecture 15",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=490201485888900",
  },
  {
    title: "Lecture 16",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=1499694730480133",
  },
  {
    title: "Lecture 17",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=1832740150413645",
  },
  {
    title: "Lecture 18",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=1139737323331176",
  },
  {
    title: "Lecture 19",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=3203143013281189",
  },
  {
    title: "Lecture 20",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=810454306884990",
  },
  {
    title: "Lecture 21",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=530115632438312",
  },
  {
    title: "Lecture 22",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=53011563243831",
  },
  {
    title: "Lecture 23",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=787991545593965",
  },
  {
    title: "Lecture 24",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=572647994433260",
  },
  {
    title: "Lecture 25",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=585964776713339",
  },
  {
    title: "Lecture 26",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=806682277445327",
  },
  {
    title: "Lecture 27",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=900628971122671",
  },
  {
    title: "Lecture 28",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=175575491882015",
  },
  {
    title: "Lecture 29",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=981868393196955",
  },
  {
    title: "Lecture 30",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=3370437199864964",
  },
  {
    title: "Lecture 31",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=583652507048379",
  },
  {
    title: "Lecture 32",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=268892515625561",
  },
  {
    title: "Lecture 33",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=1343345429729305",
  },
  {
    title: "Lecture 34",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=2317617701755561",
  },
  {
    title: "Lecture 35",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=1443228446429223",
  },
  {
    title: "Lecture 36",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=266859929292705",
  },
  {
    title: "Lecture 37",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=3385423201721394",
  },
  {
    title: "Lecture 38",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=707796594506516",
  },
  {
    title: "Lecture 39",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=620885926818049",
  },
  {
    title: "Lecture 40",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=669236778422838",
  },
  {
    title: "Lecture 41",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=1344582103145382",
  },
  {
    title: "Lecture 42",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=978294940061909",
  },
  {
    title: "Lecture 43",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=314356518036549",
  },
  {
    title: "Lecture 44",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=545985417750002",
  },
];

export const cryptoMiningData = [
  {
    title: "Crypto mining A to Z guide.",
    link: "https://www.facebook.com/share/p/1L2ejgsnUc/",
  },
  {
    title:
      "Using Hashrate to find buy sell signal on bitcoin Buy sell ka Easy Indicator using Hashrate.",
    link: "https://www.facebook.com/share/p/14dWQar2CX/",
  },
  {
    title: "Crypto Mining: How Much Can You Earn with Minimum Investment?",
    link: "https://www.facebook.com/share/p/19Nh2z5xEa/",
  },
  {
    title: "Rs. 50 Lacs Monthly earning k Liya crypto mining ki details.",
    link: "https://www.facebook.com/share/p/19kAXbsiA9/",
  },
  {
    title: "For  US members , Crypto mining details to earn 20K USD per month.",
    link: "https://www.facebook.com/share/p/1Av7oQGt58/",
  },
  {
    title: "1000 Dollars per Month without trading ? GPU VS HNT mining.",
    link: "https://www.facebook.com/share/p/18Axcn6Z1j/",
  },
];

export const youtubeMember = [
  {
    title: "Learning Series",
    link: "https://www.youtube.com/playlist?list=PLeYUkJPHvOtuiaCtvKTA5cKPqUPwYphLC",
  },
];
