import React, { useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import { NavbarAdmin } from "../../global/navbar/navbar";
import Sidebar from "../../global/sidebar/sidebar";
import { DataInfo } from "../../global/dataInfo";
import { NavbarPorfoilio } from "./porfolioNavbar";
import SidebarPortfolio from "./porfolioSidebar";
import { PortfolioPage } from "./portfolioPage";
import api from "../../../api";
import { useEffect } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { Loader } from "../../global/loader";
import { TxPortfolio } from "./txPortfolio";

export const PortfolioDashboard = () => {
  const userStore = useSelector((state) => state.userReducer);
  // const {wallet} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const alert = useAlert();
  const [loader, setLoader] = useState(false);
  const [showBalance, setShowBalance] = useState(true);
  const [ethData, setEthData] = useState({});
  const [bnbData, setBnbData] = useState({});
  const [allwallets, setAllwallets] = useState([]);
  const [wallet, setWallet] = useState("");

  const getPortfolio = async () => {
    // e.preventDefault();
    setLoader(true);
    try {
      const addResponse = await api.getWalletPortfolio(wallet);
      // console.log(addResponse);

      if (addResponse?.success) {
        setEthData(addResponse?.ethData);
        setBnbData(addResponse?.bnbData);
        setLoader(false);
      } else {
        alert.error("Something went wrong");
        setLoader(false);
      }
    } catch (e) {
      alert.show(e.message);
      // alert.show("Invalid Credentials or Account not verified");
      setLoader(false);
    }
  };

  const getWallets = async () => {
    // e.preventDefault();
    setLoader(true);
    try {
      const getResponse = await api.getWallet(userStore?.users?.user?._id);
      // console.log(getResponse);

      if (getResponse?.success) {
        setAllwallets(getResponse?.res);
        setLoader(false);
        if (getResponse?.res?.length > 0) {
          // navigate(`/portfolio/dashboard/${getResponse?.res[0]?.wallet || 1}`);
        }
      } else {
        alert.error("Something went wrong");
        setLoader(false);
      }
    } catch (e) {
      alert.show(e.message);
      // alert.show("Invalid Credentials or Account not verified");
      setLoader(false);
    }
  };
  const deleteWallet = async (wallet, userID) => {
    // e.preventDefault();
    setLoader(true);
    try {
      let payload = {
        wallet: wallet,
        id: userID,
      };
      const getResponse = await api.deleteWallet(payload);
      // console.log(getResponse);

      if (getResponse?.success) {
        setAllwallets(getResponse?.res);
        setLoader(false);
        if (getResponse?.res?.length > 0) {
          navigate(`/portfolio/dashboard/${getResponse?.res[0]?.wallet || 1}`);
        } else {
          navigate(`/portfolio/tx`);
        }
      } else {
        alert.error("Something went wrong");
        setLoader(false);
      }
    } catch (e) {
      alert.show(e.message);
      // alert.show("Invalid Credentials or Account not verified");
      setLoader(false);
    }
  };

  useEffect(() => {
    // console.log(wallet);
    // console.log(userStore?.users?.user?._id);

    if (wallet && userStore?.users?.user?._id) {
      getPortfolio();
    }
  }, [wallet]);

  useEffect(() => {
    if (userStore?.users?.user?._id) {
      getWallets();
    }
  }, [userStore?.users]);

  useEffect(() => {
    if (
      allwallets.length > 0 &&
      location.pathname == "/portfolio/dashboard/1"
    ) {
      navigate(`/portfolio/dashboard/${allwallets[0]?.wallet || 1}`);
    }
  }, [allwallets, location]);

  return (
    <>
      <div id="wrapper" className="portfolioDashboard">
        <SidebarPortfolio allwallets={allwallets} deleteWallet={deleteWallet} />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <NavbarPorfoilio ethData={ethData} wallet={wallet} />
            <Routes>
              <Route
                exact={true}
                path="/dashboard/:wallet"
                element={
                  <PortfolioPage
                    showBalance={showBalance}
                    setShowBalance={setShowBalance}
                    setLoader={setLoader}
                    setWallet={setWallet}
                    ethData={ethData}
                    bnbData={bnbData}
                    allwallets={allwallets}
                  />
                }
              />
              <Route exact={true} path="/tx" element={<TxPortfolio />} />
            </Routes>
          </div>
        </div>
      </div>
      {loader && <Loader />}
    </>
  );
};
