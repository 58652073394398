import React from "react";
import { Modal } from "react-bootstrap";
import broadCastIco from "../../images/homepage/Brodcast.webm";

export const BroadcastModal = ({ showBroadcast, handleCloseBroadcast }) => {
  return (
    <Modal
      size="lg"
      show={showBroadcast}
      onHide={handleCloseBroadcast}
      centered
    >
      <Modal.Body>
        <div className="otpModalMain broadcastModal">
          <div className="closeIcon" onClick={handleCloseBroadcast}>
            <i className="fa-solid fa-xmark" />
          </div>

          <div className="broadCastIco">
            {/* <img src={broadCastIco} alt="" /> */}
            <video
              playsInline
              webkit-playsinline
              controls={false}
              src={broadCastIco}
              autoPlay
              loop
              muted
              className="w-100"
            />
          </div>
          <div className="gradient-head">broadcast channels</div>
          <h3
            className="broadTitle mb-0"
            style={{ maxWidth: 500, margin: "auto" }}
          >
            Join our broadcast channels for exclusive crypto and stock insights,
            and track Waqar Zaka's investments.
          </h3>
          <div className="broadBtnMain">
            <a
              className="themeBtn"
              href="https://www.instagram.com/channel/AbbEEsvUoFTOHaPf/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* <i className="fa-brands fa-instagram"></i> */}
              Insta Broadcast
            </a>
            <a
              className="borderBtn"
              href="https://m.me/j/Abat_s17duf8Dm-5/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* <i className="fa-brands fa-facebook"></i> */}
              FB Broadcast
            </a>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
