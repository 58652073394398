import React, { useEffect } from "react";

export const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
      <div className="termsPage">
        <h1>Privacy Policy </h1>
        <p className="mb-3">
          <b>Last Updated:</b> 01.03.2025{" "}
        </p>

        <h2>1. Introduction </h2>
        <p>
          Welcome to Team <b>WaqarZaka!</b> Your privacy is important to us.
          This Privacy Policy explains how we collect, use, and protect your
          information when you use our Web3 education platform. By using our
          services, you agree to the terms outlined in this policy.{" "}
        </p>
        <h2>2. Information We Collect </h2>
        <p>
          When you sign up on{" "}
          <a
            href="https://WaqarZaka.net"
            target="_blank"
            rel="noopener noreferrer"
          >
            WaqarZaka.net
          </a>
          , we may collect the following information:{" "}
        </p>
        <ul>
          <li>
            <b>Personal Information</b>: Name, email address, gender, date of
            birth, country, and WhatsApp number.{" "}
          </li>
          <li>
            <b>Login Credentials</b>: Passwords (encrypted and secured).{" "}
          </li>
          <li>
            <b>Usage Data</b>: IP address, browser type, and access logs for
            security purposes.
          </li>
        </ul>

        <h2>3. How We Use Your Information </h2>
        <p>We use the collected data for the following purposes: </p>
        <p>✔ To provide secure account access. </p>
        <p>✔ To enhance your user experience. </p>
        <p>✔ To communicate important updates & announcements. </p>
        <p>
          ✔ To send marketing emails and feature updates (you can opt out
          anytime).
        </p>
        <p>✔ To ensure compliance with our Terms & Conditions.</p>

        <h2>4. Data Protection & Security </h2>
        <p>We take strict measures to protect your data: </p>
        <ul>
          <li>All passwords are encrypted.</li>
          <li>
            Sensitive information is stored with secure encryption methods.{" "}
          </li>
          <li>
            Regular security audits are performed to prevent data breaches.{" "}
          </li>
        </ul>
        <h2>5. No Data Sharing with Third Parties</h2>
        <p>
          We <b>DO NOT</b> sell, rent, or share your data with any{" "}
          <b>third-party organizations</b>. Your personal information remains
          strictly confidential and is used only for internal purposes related
          to our platform.{" "}
        </p>

        <h2>6. User Rights & Control</h2>
        <p>You have full control over your data:</p>
        <ul>
          <li>
            <b>Unsubscribe</b>: You can opt out of marketing emails anytime.{" "}
          </li>
          <li>
            <b>Delete Account</b>: You may request account deletion by
            contacting our support team.
          </li>
        </ul>

        <h2>7. Cookies & Tracking </h2>
        <p>To improve user experience, we may use cookies for: </p>
        <p>Enhancing website performance.</p>
        <p>Analyzing visitor trends and platform activity. </p>
        <p>Securing login sessions. </p>

        <p className="mt-3">
          Users can disable cookies in their browser settings if they prefer.
        </p>

        <h2>8. Legal Compliance</h2>
        <p>
          We comply with global <b>data protection laws</b>, including:{" "}
        </p>
        <ul>
          <li>
            <b>UK’s GDPR (General Data Protection Regulation)</b>
          </li>
          <li>
            <b>US Data Protection Laws</b>
          </li>
          <li>
            <b>Pakistan’s PECA (Prevention of Electronic Crimes Act, 2016)</b>
          </li>
        </ul>
        <p>
          If required by law enforcement, we may disclose user data in
          compliance with legal requests.
        </p>

        <h2>9. Changes to Privacy Policy</h2>
        <p>
          We may update this Privacy Policy periodically. Users will be notified
          of any major changes. Continued use of the platform constitutes
          acceptance of the updated policy.
        </p>
        <h2>10. Contact Us</h2>
        <p>For any privacy-related concerns or questions, reach out to: </p>
        <div>
          <b>Email:</b>
          <a href="mailto:support@waqarzaka.net " className="font-400 ms-1">
            support@waqarzaka.net
          </a>
        </div>
        <div>
          <b>Website:</b>
          <a
            href="https://waqarzaka.net"
            target="_blank"
            className="font-400 ms-1"
          >
            https://waqarzaka.net
          </a>
        </div>

        <p className="mt-3">
          By using our platform, you acknowledge and accept our{" "}
          <b>Privacy Policy</b>.{" "}
        </p>
      </div>
    </div>
  );
};
